@charset "UTF-8";
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* テーマカラー */
  --theme-color-1: #c6a886;
  --theme-color-2: #ad9f8f; /* #08255b */
  --theme-color-3: #c6a886;
  --theme-color-4: #f0e4d6;
  --theme-color-5: #f8ede1;
  /* 白黒カラー */
  --text-color-white: #fff;
  --text-color-black: #000;
  /* ベースカラー（一般画面：背景色） */
  --background-color: #fff;
  /* 一般画面：デフォルト文字カラー */
  --default-text-color: #000;
  /* 一般画面デフォルトアクセントカラー（ボタン等） */
  --public-default-accent-color: var(--theme-color-1);
  /* 商品価格文字色 */
  --price-color: #ff0000;
  /* トップページ：「ご挨拶」色 */
  --top-h2-greeting-title-color: var(--theme-color-1);
  /* トップページ：「ご挨拶」下破線色 */
  --introduce-dash-color: #000;
  /* トップページ：ご挨拶エリア文字色 */
  --introduce-text-color: var(--default-text-color);
  /* トップページ：ご挨拶エリア背景色 */
  --introduce-background-color: var(--theme-color-6);
  /* トップページ：「Greetings」英語テキスト色 */
  --top-h2-greeting-title-en-color: var(--theme-color-3);
  /* トップページ：h2タイトルバー背景色 */
  --top-h2-title-background-color: var(--theme-color-4);
  /* トップページ：h2タイトルバー文字色 */
  --top-h2-title-text-color: var(--text-color-black);
  /* トップページ：h2タイトルバー枠線下色 */
  --top-h2-title-border-bottom-color: var(--theme-color-6);
  /* トップページ：h2タイトルバー枠線左色 */
  --top-h2-title-border-left-color: var(--theme-color-1);
  /* トップページ：h2タイトルバー英語テキスト色 */
  --top-category-list-area-english: var(--theme-color-1);
  /* サイドバー：カテゴリ色 */
  --sidebar-category-border-color: var(--theme-color-3);
  /* サイドバー：カテゴリ背景色 */
  --sidebar-category-text-color: #000;
  /* サイドバー：カテゴリ背景色 */
  --sidebar-category-background-color: #fff;
  /* サイドバー：検索ボタン色 */
  --sidebar-search-btn-color: var(--theme-color-1);
  /* サイドバー：検索ボタン文字色 */
  --sidebar-search-btn-text-color: var(--text-color-white);
  /* サイドバー：コンテンツ文字色 */
  --sidebar-contents-text-color: var(--text-color-black);
  /* サイドバー：コンテンツ「contents」,線アクセント色 */
  --sidebar-contents-color: var(--theme-color-1);
  /* サイドバー：コンテンツ線色 */
  --sidebar-contents-bar-base-color: #e8e8e8;
  /* ヘッダー背景色 */
  --header-background-color: #ffffff;
  /* ヘッダー枠線色 */
  --header-border-color: var(--theme-color-3);
  /* ヘッダー：お気に入り・買い物カゴバッジ色 */
  --header-cnt-badge-color: #ff0000;
  /* フッター上部：背景色 */
  --footer-top-background-color: #fff;
  /* フッター上部：文字色 */
  --footer-top-text-color: #000;
  /* フッター中間：背景色 */
  --footer-middle-background-color: #fff;
  /* フッター中間：文字色 */
  --footer-middle-text-color: #000;
  /* フッター下部：背景色 */
  --footer-bottom-background-color: var(--theme-color-3);
  /* フッター下部：文字色 */
  --footer-bottom-text-color: #fff;
  /* フッター：電話番号文字色 */
  --footer-tel-number-color: var(--theme-color-1);
  /* フッター：お問い合わせリンクボタン背景色 */
  --footer-contact-btn-background-color: var(--theme-color-1);
  /* フッター：お問い合わせリンクボタン文字色 */
  --footer-contact-btn-text-color: var(--text-color-white);
  /* フッター：お問い合わせリンクボタン枠色 */
  --footer-contact-btn-border-color: var(--theme-color-1);
  /* フッター：「公式SNS」文字色 */
  --footer-official-sns-text-color: #fff;
  /* フッター：「公式SNS」背景色 */
  --footer-official-sns-background-color: #000;
  /* 商品詳細：お気に入りボタン色 */
  --favorite-btn-color: var(--theme-color-1);
  /* ハンバーガーメニュー：背景色 */
  --hamburger-menu-background-color: var(--theme-color-2);
  /* ハンバーガーメニュー：3本線ボタン色 */
  --hamburger-menu-link-btn-color: var(--theme-color-1);
  /* ハンバーガーメニュー：各種リンクホバー色 */
  --hamburger-menu-hover-color: var(--theme-color-3);
  /* ハンバーガーメニュー：各種ボタン色 */
  --hamburger-menu-btn-color: var(--theme-color-3);
  /* 新規会員登録・ログイン・会員メニューボタン */
  --member-menu-btn-color: var(--theme-color-1);
  /* 「商品一覧へ」ボタン */
  --item-list-link-btn-color: var(--theme-color-1);
  /* ご利用ガイド・プライバシーポリシー見出し色 */
  --public-h2-text-color: var(--theme-color-1);
  /* 管理画面：ヘッダー背景色 */
  --admin-header-background-color: var(--text-color-white);
  /* 管理画面：ヘッダー文字色 */
  --admin-header-font-color: var(--text-color-black);
  /* 管理画面：強調色（ボタン等） */
  --admin-default-accent-color: var(--theme-color-1);
}

code {
  /* font-family: '丸ゴシック－Ｍ', 'ヒラギノ丸ゴ Pro W4', 'ヒラギノ丸ゴ Pro', 'Hiragino Maru Gothic Pro',
    'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'HG丸ｺﾞｼｯｸM-PRO', 'HGMaruGothicMPRO'; */
  font-family: "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "游明朝", "YuMincho", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

.color_red {
  color: #ff0000;
}

main {
  padding-bottom: 200px;
}

#public_page button,
input {
  /* font-family: '丸ゴシック－Ｍ', 'ヒラギノ丸ゴ Pro W4', 'ヒラギノ丸ゴ Pro', 'Hiragino Maru Gothic Pro',
    'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'HG丸ｺﾞｼｯｸM-PRO', 'HGMaruGothicMPRO'; */
  font-family: "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "游明朝", "YuMincho", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

input::-moz-placeholder {
  color: #b9b9b9;
}

input::placeholder {
  color: #b9b9b9;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #fff;
}

#public_page .sp_txt {
  display: none;
}

.pc_show {
  display: block;
}

.sp_show {
  display: none;
}

/* 
input[type="number"]のスピンを非表示
https://qiita.com/chimayu/items/58e3481305e91d188be3
*/
.no_spin::-webkit-inner-spin-button,
.no_spin::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

/************************
ローディング
************************/
#loading_area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  background-color: var(--theme-color-1);
  z-index: 1000;
}

#loading_area.hidden {
  display: none;
}

#loading_area .loader {
  margin: 100px auto;
  font-size: 25px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  top: calc(50% - 5em);
  position: relative;
  text-indent: -9999em;
  animation: load5 1.1s infinite ease;
  transform: translateZ(0);
}
@keyframes load5 {
  0%, 100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
/************************
管理者ページ/shop_admin/ #admins_page
************************/
#admins_page * {
  font-family: "丸ゴシック－Ｍ", "ヒラギノ丸ゴ Pro", "Hiragino Maru Gothic Pro", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "HG丸ｺﾞｼｯｸM-PRO", "HGMaruGothicMPRO";
  box-sizing: border-box;
}

#admins_page .admin_btn_area {
  display: block;
  text-align: center;
  margin-top: 50px;
}

#admins_page .admin_btn_area button {
  color: #ffffff;
  background-color: #373239;
  border-radius: 0;
  box-shadow: none;
  padding: 10px 50px;
  letter-spacing: 0.5em;
}

#admins_page input[type=password] {
  font-family: sans-serif;
}

/************************
ヘッダー(管理画面)
************************/
/* 管理画面：ヘッダー  --山下追記コメント */
#admins_page header {
  background-color: var(--admin-header-background-color);
  color: var(--admin-header-font-color);
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 65px;
  box-sizing: border-box;
  z-index: 5;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 45px;
  top: 0;
}

#admins_page header .header_logo {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

#admins_page header .header_logo img {
  height: auto;
  /* width: 50px; */
  width: 200px;
  cursor: pointer;
}

#admins_page header .right_area {
  flex: 1;
  text-align: right;
}

#admins_page header .right_area span.name {
  margin-right: 10px;
}

/* 管理画面：ログアウトボタン  --山下追記コメント */
#admins_page header .right_area .logout_btn {
  padding: 5px 30px;
  color: #ffffff;
  background-color: var(--admin-default-accent-color);
  border: 1px solid #ffffff;
}

/* #admins_page header .right_area .logout_btn:hover {
  padding: 5px 30px;
  color: #000000;
  background-color: var(--admin-default-accent-color);
  border: 1px solid #ffffff;
} */
#admins_page header .right_area .logout_btn span {
  padding-right: 20px;
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: center right;
}

#admins_page header .right_area .header_name_area {
  display: inline-block;
  margin-right: 10px;
}

/************************
サイドバー(管理画面)
************************/
#admins_page aside.sidebar {
  height: 100%;
  position: fixed;
  width: 200px;
  background-color: #f2f2f2;
  box-sizing: border-box;
  padding-top: 65px;
  padding-bottom: 30px;
  overflow-y: auto;
  z-index: 100;
}

#admins_page aside.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#admins_page aside.sidebar li {
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  padding: 20px;
  line-height: 2.5em;
  position: relative;
}

#admins_page aside.sidebar li.active,
#admins_page aside.sidebar li:hover {
  background-color: var(--admin-default-accent-color);
  color: #ffffff;
}

/************************
ログイン画面(管理画面)
************************/
#admins_page .subline {
  width: 80%;
  margin: 0 auto;
}

#admins_page .form_type_3 {
  padding-top: 65px;
}

#admins_page .form_type_3 h2 {
  text-align: center;
  font-weight: 400;
  margin: 65px 0;
}

#admins_page .form_type_3 .form_area {
  max-width: 1000px;
  margin: 0 auto;
  border: 1px solid #000000;
  padding: 50px 0;
  margin-bottom: 80px;
}

#admins_page .form_type_3 .form_area p {
  text-align: center;
}

#admins_page .form_type_3 .form_area form {
  margin-top: 80px;
  width: 60%;
  margin: 80px auto;
}

#admins_page .form_type_3 .form_area form dl {
  margin-bottom: 50px;
}

#admins_page .form_type_3 .form_area form dd {
  margin-left: 0;
}

#admins_page .form_type_3 .form_area form input {
  width: 100%;
  margin-top: 5px;
  padding: 5px;
  border: 1px solid #cccccc;
}

#admins_page .form_type_3 .form_area p.password_application_btn {
  text-decoration: underline;
  cursor: pointer;
}

#admins_page .form_type_3 .button_area {
  text-align: center;
  margin-top: 80px;
}

#admins_page .form_type_3 .button_area button {
  width: 150px;
  background-color: var(--admin-default-accent-color);
  color: #ffffff;
  padding: 10px;
  border: none;
  margin: 0 20px;
}

#admins_page .form_type_3 .button_area button.desabled {
  background-color: #cccccc;
  pointer-events: none;
}

/************************
メインコンテンツ(管理画面)
************************/
#admins_page main {
  position: absolute;
  left: 15%;
  top: 65px;
  width: 85%;
  padding: 20px 50px 50px;
}

#admins_page main h2.page_title {
  text-align: center;
  margin-bottom: 80px;
}

/* 管理画面：共通：タイトル文字オプション  --山下追記コメント */
#admins_page main h2 span {
  border-bottom: 1px solid var(--admin-default-accent-color);
}

#admins_page .breadcrumb {
  margin: 10px 0;
}

#admins_page main .subline {
  width: 90%;
  display: block;
  margin: 0 auto;
}

/************************
ページング
************************/
.paging_area {
  text-align: right;
  margin: 20px 0;
}

.paging_area button {
  margin: 0 2px;
  border-radius: 0;
  padding: 7px 9px;
  border: 1px solid #969696;
  background-color: #ffffff;
  vertical-align: middle;
}

.paging_area button.active {
  border: 1px solid #969696;
  background-color: #969696;
  color: #ffffff;
  cursor: inherit;
}

.paging_area button.pre_btn,
.paging_area button.next_btn {
  padding: 7px;
}

#admins_page button.back_btn {
  padding: 10px;
  margin-top: 20px;
  border: none;
  background-color: #edeae4;
  color: #000000;
  float: right;
}

/************************
ホーム /admin/home
************************/
#admins_page #home_page .situation_area {
  display: inline-block;
  width: calc(50% - 60px);
  min-width: 300px;
  margin: 0 30px;
}

#admins_page #home_page .situation_area ul {
  list-style: none;
  border: 1px solid #808080;
  padding: 40px 30px;
}

#admins_page #home_page .situation_area ul li {
  border-bottom: 1px dotted #808080;
  line-height: 3.5em;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

#admins_page #home_page .situation_area ul li:last-child {
  border-bottom: none;
}

/************************
管理画面共通
************************/
#admins_page .box_type_1 {
  margin-bottom: 50px;
}

#admins_page .box_type_1 .title_area {
  border: 1px solid #8c8c8c;
  padding: 10px 30px;
  background-color: #e6e6e6;
}

#admins_page .box_type_1 .title_area h3 {
  border: none;
  margin: 0;
}

#admins_page .box_type_1 .content_area {
  border: 1px solid #cccccc;
}

#admins_page .search_area.box_type_1 .content_area {
  padding: 15px 30px;
}

#admins_page .box_type_1 .content_area dl {
  display: flex;
}

#admins_page .box_type_1 .content_area dl dt {
  background-color: #cccccc;
  padding: 8px 15px;
}

#admins_page .box_type_1 .content_area dl dd {
  margin-left: 10px;
}

#admins_page .box_type_1 .content_area dl dd.right {
  margin-left: auto;
}

#admins_page .box_type_1 .content_area dl dd input {
  padding: 10px;
  width: 35vw;
  border: 1px solid #cbcbcb;
}

#admins_page .box_type_1 .content_area dl dd button {
  padding: 10px 50px;
  letter-spacing: 5px;
  color: #ffffff;
  background-color: #373239;
  border: none;
  cursor: pointer;
}

#admins_page .box_type_1 .content_area table {
  width: 100%;
  border-collapse: collapse;
}

#admins_page .box_type_1 .content_area table th {
  width: 12em;
  text-align: left;
  vertical-align: middle;
}

#admins_page .box_type_1 .content_area table td.flex {
  display: flex;
  align-items: center;
}

#admins_page .box_type_1 .content_area table td.birthday_box span {
  margin-left: 20px;
}

#admins_page .box_type_1 .content_area table td.mail_magazine_flag_box label:first-child input[type=radio] {
  display: inline-block;
  margin-bottom: 20px;
}

#admins_page .box_type_1 .content_area table td.flex span.header {
  width: 80px;
  text-align: right;
}

#admins_page .box_type_1 .content_area table td input[type=text] {
  width: calc(100% - 50px);
  max-width: 450px;
  padding: 6px;
  border: 1px solid #cbcbcb;
}

#admins_page .box_type_1 .content_area table td input[type=number] {
  width: calc(100% - 50px);
  max-width: 450px;
  padding: 6px;
  border: 1px solid #cbcbcb;
}

#admins_page .box_type_1 .content_area table td input[name=postal_code].read_only {
  background-color: #edeae4;
}

#admins_page .box_type_1 .content_area table td input[name=address] {
  background-color: #edeae4;
}

#admins_page .box_type_1 .content_area table td input[name=address_1] {
  background-color: #edeae4;
}

#admins_page .box_type_1 .content_area table td input[name=re_password] {
  margin-top: 30px;
}

#admins_page .box_type_1 .content_area table td input[name=family_name],
#admins_page .box_type_1 .content_area table td input[name=first_name],
#admins_page .box_type_1 .content_area table td input[name=family_name_furigana],
#admins_page .box_type_1 .content_area table td input[name=first_name_furigana] {
  margin-left: 10px;
  margin-right: 30px;
}

#admins_page .box_type_1 .content_area table td select {
  width: 100%;
  max-width: 200px;
  padding: 5px;
  border: 1px solid #cbcbcb;
}

#admins_page .box_type_1 .content_area table td button {
  vertical-align: top;
  margin-right: 20px;
}

#admins_page .box_type_1 .content_area table td button.file_btn {
  border: none;
  padding: 0;
}

#admins_page .box_type_1 .content_area table td button.file_btn label {
  padding: 7px 25px;
  display: block;
  cursor: pointer;
}

#admins_page .box_type_1 .content_area table td textarea {
  width: 100%;
  max-width: 450px;
  resize: none;
  padding: 5px;
  border: 1px solid #767676;
}

#admins_page .box_type_1 .content_area table td .radio_btn_label {
  margin-right: 40px;
}

#admins_page .box_type_1 .content_area table td.td_flex {
  display: flex;
}

#admins_page .box_type_1 .content_area table td .yen_icon_area {
  width: 40px;
  display: inline-block;
  text-align: center;
  border: 1px solid #767676;
  padding: 2.5px 0;
  background-color: #f2f2f2;
  border-right: none;
}

#admins_page .box_type_1 .button_area,
#admins_page .box_type_2 .button_area {
  margin-top: 50px;
  text-align: center;
}

/* 登録・送信ボタン（管理画面：各新規登録画面、編集画面）  --山下追記コメント */
#admins_page .box_type_1 .button_area button,
#admins_page .box_type_2 .button_area button {
  width: 150px;
  background-color: var(--admin-default-accent-color);
  color: #ffffff;
  padding: 10px;
  border: none;
  margin: 0 20px;
}

#admins_page .box_type_2 .button_area #save_btn {
  background-color: #767676;
}

#admins_page .box_type_2 .button_area #save_btn.desabled {
  background-color: #cccccc;
  pointer-events: none;
}

#admins_page .box_type_1 .button_area button.desabled,
#admins_page .box_type_2 .button_area button.desabled {
  background-color: #cccccc;
  pointer-events: none;
}

#admins_page .box_type_1 .progress_show,
#admins_page .box_type_2 .progress_show {
  display: none;
}

#admins_page .box_type_1 .progress .progress_show,
#admins_page .box_type_2 .progress .progress_show {
  display: inline;
}

#admins_page .box_type_1 .progress .progress_none,
#admins_page .box_type_2 .progress .progress_none {
  display: none;
}

#admins_page .box_type_1 .thumbnail_area,
#admins_page .box_type_2 .thumbnail_area {
  display: inline-block;
  position: relative;
}

#admins_page .box_type_1 .thumbnail_area img,
#admins_page .box_type_2 .thumbnail_area img {
  width: 200px;
  height: auto;
  min-width: 100px;
  min-height: 100px;
  border: 1px dotted #e3e3e3;
}

#admins_page .box_type_1 .thumbnail_area .image_delete,
#admins_page .box_type_2 .thumbnail_area .image_delete {
  margin: 0;
  border: 1px solid #cccccc;
  border-radius: 100%;
  position: absolute;
  right: -30px;
  top: -7px;
  width: 26px !important;
  height: 26px;
  background-repeat: no-repeat;
}

#admins_page .box_type_1 .thumbnail_area .image_delete span,
#admins_page .box_type_2 .thumbnail_area .image_delete span {
  font-size: 16px;
  position: absolute;
  top: -1px;
  left: 7px;
  pointer-events: none;
}

#admins_page .box_type_2 dd button.file_btn {
  border: none;
  padding: 0;
  vertical-align: top;
  margin-right: 20px;
}

#admins_page .box_type_2 dd button.file_btn label {
  padding: 7px 25px;
  display: block;
  cursor: pointer;
}

#admins_page #setting_page .box_type_1 .content_area {
  padding: 0;
}

#admins_page .box_type_1 form,
#admins_page .box_type_2 form {
  width: 100%;
}

#admins_page .box_type_1 th {
  background-color: #edead3;
  padding: 20px 30px;
  border-bottom: 1px solid #8c8c8c;
}

#admins_page .box_type_1 td {
  padding: 20px 30px;
  border-bottom: 1px solid #8c8c8c;
  word-break: break-all;
}

#admins_page .box_type_1 tr:last-child th,
#admins_page .box_type_1 tr:last-child td {
  border-bottom: none;
}

#admins_page #setting_page .box_type_1 td input.tax_input {
  width: 70px;
  margin-right: 10px;
}

#admins_page .box_type_2 dl {
  margin-bottom: 60px;
}

#admins_page .box_type_2 dt {
  margin: 20px 0;
}

#admins_page .box_type_2 dd input[type=text] {
  width: 100%;
  padding: 5px;
  border: 1px solid #bcbcbc;
}

#admins_page .box_type_2 dd input[name=publication_date] {
  width: 200px;
  padding: 5px;
  margin-right: 30px;
}

#admins_page .box_type_2 dd select {
  width: 70px;
  padding: 5px;
  border: 1px solid #bcbcbc;
}

#admins_page .box_type_2 dd .mailing_list_area {
  margin-top: 10px;
}

#admins_page .box_type_2 dd .delete_btn {
  color: #ff0000;
  border-color: #ff0000;
  display: block;
  margin: auto;
  background-color: #ffffff;
  padding: 3px 10px;
  border-style: solid;
  margin-left: 40px;
}

#admins_page .box_type_2 dd .radio_btn_label {
  margin-right: 40px;
}

/* 管理画面：メールマガジン新規登録：リストから選択ボタン  --山下追記コメント */
#admins_page .box_type_2 dd button.add_btn {
  padding: 8px 30px;
  color: #ffffff;
  background-color: var(--admin-default-accent-color);
  border: none;
  cursor: pointer;
}

#admins_page .box_type_2 dd textarea {
  padding: 5px;
  width: 100%;
  resize: none;
}

#admins_page .sort_menu_area {
  display: flex;
  margin-bottom: 20px;
}

#admins_page .sort_menu_area .left_group {
  display: flex;
}

#admins_page .sort_menu_area .left_group select {
  padding: 10px 5px;
  margin-right: 10px;
  border: 1px solid #cbcbcb;
}

#admins_page .sort_menu_area .left_group button {
  padding: 10px 25px;
  letter-spacing: 5px;
  color: #ffffff;
  background-color: #373239;
  border: none;
  cursor: pointer;
}

#admins_page .sort_menu_area .right_group {
  margin-left: auto;
}

/* 管理画面：共通：新規登録ボタン  --山下追記コメント */
#admins_page .sort_menu_area .right_group button {
  padding: 10px 30px 10px 50px;
  color: #ffffff;
  letter-spacing: 2px;
  background-color: var(--admin-default-accent-color);
  border: none;
  cursor: pointer;
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 23px center;
}

#admins_page .list_area table {
  width: 100%;
  border-collapse: collapse;
}

#admins_page .list_area table th {
  background-color: #edead3;
  border: 1px solid #8c8c8c;
  padding: 10px 0;
}

#admins_page .list_area table td {
  border: 1px solid #8c8c8c;
  padding: 10px 5px;
}

#admins_page .list_area table td.checkbox_box {
  text-align: center;
  width: 50px;
}

#admins_page .list_area table td.checkbox_box input {
  margin: 0;
}

#admins_page .list_area table td.image_box {
  text-align: center;
}

#admins_page .list_area table td.image_box .thumbnail_area {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

#admins_page .list_area table td.category_box {
  text-align: center;
}

#admins_page .list_area table td.price_box {
  text-align: right;
}

#admins_page .list_area table td.stock_quantity_box {
  text-align: right;
}

#admins_page .list_area table td.department_box {
  text-align: center;
}

#admins_page .list_area table td.status_box {
  text-align: center;
}

#admins_page .list_area table td.datetime_box {
  text-align: center;
}

#admins_page .list_area table td.button_box {
  text-align: center;
  width: 100px;
}

#admins_page .list_area table td.image_box div {
  display: inline-block;
  width: 100px;
  height: 100px;
  border: 1px solid #aaaaaa;
}

#admins_page .list_area table td.button_box button {
  display: block;
  margin: auto;
  background-color: #ffffff;
  padding: 3px 10px;
  border-style: solid;
}

#admins_page .list_area table td.button_box button:first-child {
  margin-bottom: 10px;
}

#admins_page .list_area table td.button_box button.delete_btn {
  color: #ff0000;
  border-color: #ff0000;
}

#admins_page #mailing_list_edit_page .list_area table td.button_box button.delete_btn {
  margin-bottom: 0;
}

#admins_page .list_area table td.button_box button.add_btn {
  margin-bottom: 0;
}

#admins_page .list_area table td.name_box span.margin_right_10px {
  margin-right: 10px;
}

#admins_page .under_menu_area {
  display: flex;
  margin-top: 30px;
}

#admins_page .under_menu_area .left_group {
  display: flex;
}

#admins_page .under_menu_area .left_group select {
  padding: 10px 5px;
  margin-right: 10px;
  border: 1px solid #cbcbcb;
}

#admins_page .under_menu_area .left_group button {
  padding: 10px 25px;
  letter-spacing: 5px;
  color: #ffffff;
  background-color: #373239;
  border: none;
  cursor: pointer;
}

#admins_page #item_list_page .list_area table td {
  word-break: break-all;
}

#admins_page #item_list_page .list_area table td.checkbox_box {
  min-width: 50px;
}

#admins_page #item_list_page .list_area table td.image_box {
  /* max-width: 125px;
  max-width: 150px; */
}

#admins_page #item_list_page .list_area table td.name_box {
  min-width: 300px;
  max-width: 300px;
}

#admins_page #item_list_page .list_area table td.category_box {
  min-width: 150px;
  max-width: 150px;
}

#admins_page #item_list_page .list_area table td.status_box {
  min-width: 80px;
  max-width: 80px;
}

#admins_page #item_list_page .list_area table td.price_box {
  min-width: 150px;
  max-width: 150px;
}

#admins_page #item_list_page .list_area table td.stock_quantity_box {
  min-width: 100px;
  max-width: 100px;
}

#admins_page #item_list_page .list_area table td.department_box {
  min-width: 100px;
  max-width: 100px;
}

#admins_page #item_list_page .list_area table td.datetime_box {
  /* max-width: 80px; */
  min-width: 220px;
}

#admins_page #item_list_page .list_area table td.button_box {
  min-width: 80px;
  max-width: 80px;
}

#admins_page #order_list_page .list_area table td {
  word-break: break-all;
  text-align: center;
}
#admins_page #order_list_page .list_area table td.order_datetime {
  min-width: 220px;
  text-align: center;
}
#admins_page #order_list_page .list_area table td.member_name {
  min-width: 150px;
}
#admins_page #order_list_page .list_area table td.member_name {
  min-width: 150px;
}
#admins_page #order_list_page .list_area table td.tel_number {
  min-width: 150px;
}
#admins_page #order_list_page .list_area table td.order_info {
  min-width: 300px;
  text-align: left;
}
#admins_page #order_list_page .list_area table td.quantity {
  min-width: 100px;
}
#admins_page #order_list_page .list_area table td.detail_btn {
  min-width: 70px;
}
#admins_page #order_list_page .list_area table td.detail_btn button {
  background-color: var(--admin-default-accent-color);
  color: #fff;
  border: none;
  padding: 10px;
  min-width: 50px;
}

#admins_page #member_list_page .list_area table td.address_box {
  max-width: 350px;
  word-wrap: break-word;
}

#admins_page #member_list_page .list_area table td.name_box {
  max-width: 150px;
  word-wrap: break-word;
}

#admins_page #member_list_page .list_area table td.mail_address_box {
  max-width: 200px;
  word-wrap: break-word;
}

/* 管理画面：メールマガジン新規登録：メーリングリスト選択画面：追加ボタン  --山下追記コメント */
#admins_page .under_menu_area .left_group button.add_btn {
  padding: 10px 30px 10px 50px;
  color: #ffffff;
  letter-spacing: 2px;
  background-color: var(--admin-default-accent-color);
  border: none;
  cursor: pointer;
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 23px center;
}

#admins_page .under_menu_area .right_group {
  margin-left: auto;
}

#admins_page .under_menu_area .right_group .paging_area {
  margin: 0;
}

#admins_page .under_menu_area .right_group .paging_area button {
  border: none;
  margin-left: 10px;
}

/* 管理画面：共通：ページ選択ボタン：現在選択されているページ  --山下追記コメント */
#admins_page .under_menu_area .right_group .paging_area .active {
  background-color: var(--admin-default-accent-color);
}

/* 管理画面：共通：ページ選択ボタン：マウスカーソルが合わさっている時  --山下追記コメント */
#admins_page .under_menu_area .right_group .paging_area button:hover {
  background-color: var(--admin-default-accent-color);
  color: #ffffff;
}

/* 管理画面：共通：ページ選択ボタン：前へボタン&次へボタン  --山下追記コメント */
#admins_page .under_menu_area .right_group .paging_area .pre_btn,
#admins_page .under_menu_area .right_group .paging_area .next_btn {
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--admin-default-accent-color);
  color: #ffffff;
}

/* add 20211116 住所自動入力ボタン */
/* 管理画面：会員新規登録：郵便番号から検索ボタン  --山下追記コメント */
#admins_page #add_form td button,
#admins_page #update_form td button,
#admins_page #member_edit_page #add_form td button,
#admins_page #member_edit_page #update_form td button,
#admins_page #admin_add_page #add_form td button {
  width: 150px;
  background-color: var(--admin-default-accent-color);
  color: #ffffff;
  padding: 5px 12px;
  border: none;
  margin: 0 20px;
  border-radius: 2px;
}

#admins_page #item_edit_page #update_form td button.file_btn,
#admins_page #item_add_page #add_form td button.file_btn {
  padding: 0;
}

/************************
商品一覧(管理画面)
************************/
#admins_page .title_content_box {
  margin-bottom: 30px;
}

#admins_page .title_content_box h2 {
  margin: 0;
  padding: 10px 20px;
  background-color: #e2e2e2;
}

#admins_page .title_content_box .title_box {
  margin: 0;
  padding: 10px 20px;
}

#admins_page .search_form_area.title_content_box .title_box {
  background-color: #e2e2e2;
  border: none;
}

#admins_page .search_form_area.title_content_box .content_box {
  border: #e2e2e2 1px solid;
  padding: 30px 50px;
}

#admins_page .title_content_box .content_box form {
  width: 100%;
}

#admins_page .title_content_box .content_box form .search_box {
  display: inline-block;
  margin-right: 30px;
}

#admins_page .title_content_box .content_box form .search_box dl {
  display: flex;
  align-items: center;
}

#admins_page .title_content_box .content_box form .search_box dt {
  width: 100px;
  background-color: #e2e2e2;
  text-align: center;
  padding: 10px;
}

#admins_page .title_content_box .content_box form .search_box .mailaddress_input dt {
  width: 150px;
}

#admins_page .title_content_box .content_box form .search_box dd {
  margin-left: 10px;
}

#admins_page .title_content_box .content_box form .search_box dd select {
  padding: 11px;
  font-size: 16px;
}

#admins_page .title_content_box .content_box form .search_btn_area {
  text-align: center;
  margin-top: 30px;
}

#admins_page .title_content_box .content_box form .search_btn_area button {
  color: #ffffff;
  background-color: #373239;
  border-radius: 0;
  box-shadow: none;
  padding: 10px 50px;
  letter-spacing: 0.5em;
}

#admins_page .sort_add_area {
  margin-bottom: 10px;
  display: flex;
}

#admins_page .sort_add_area #sort_select {
  padding: 0 5px;
}

#admins_page .sort_add_area .sort_btn {
  background-color: #373239;
  border: none;
  color: #ffffff;
  letter-spacing: 0.2em;
  padding: 8px 15px;
  margin-left: 20px;
}

#admins_page .sort_add_area .add_btn {
  background-color: #e5767c;
  border: none;
  color: #ffffff;
  padding: 8px 15px;
  margin-left: auto;
}

#admins_page .sort_add_area .add_btn span {
  background-color: #ffffff;
  color: #e5767c;
  border-radius: 50%;
  padding: 1px 5px;
  margin-right: 7px;
}

#admins_page table.list_table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

#admins_page table.list_table th,
#admins_page table.list_table td {
  border: 1px solid #373239;
}

#admins_page table.list_table th {
  background-color: #e0eaf5;
  padding: 20px 0;
}

#admins_page table.list_table td .thumbnail_area {
  width: 120px;
  height: 90px;
  background-color: #e6e6e6;
  margin: 10px auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

#admins_page table.list_table th.thumbnail_item {
  width: 160px;
}

#admins_page table.list_table th.state_item {
  width: 100px;
}

#admins_page table.list_table th.edit_delete_item {
  width: 100px;
}

#admins_page table.list_table td.item_name_item {
  text-align: left;
  padding-left: 10px;
}

#admins_page table.list_table td button {
  background-color: inherit;
  border: none;
  display: block;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.2em;
  margin: 0 auto;
  padding: 5px 15px;
}

#admins_page table.list_table td button:hover {
  background-color: #e2e2e2;
}

#admins_page table.list_table td .delete_btn {
  color: #ff0000;
}

#admins_page #item_list_page .list_area table th:nth-child(4),
#admins_page #item_list_page .list_area table th:nth-child(5),
#admins_page #item_list_page .list_area table th:nth-child(8) {
  width: 80px;
}

#admins_page #item_list_page .list_area table th:nth-child(6),
#admins_page #item_list_page .list_area table th:nth-child(7) {
  width: 60px;
}

#admins_page #item_list_page .list_area table td:nth-child(4),
#admins_page #item_list_page .list_area table td:nth-child(5),
#admins_page #item_list_page .list_area table td:nth-child(6),
#admins_page #item_list_page .list_area table td:nth-child(7) {
  text-align: center;
}

/************************
商品情報追加(管理画面)
************************/
#admins_page .title_content_box .title_box {
  background-color: #e0eaf5;
  border: 1px solid #373239;
}

#admins_page .title_content_box .content_box {
  padding: 15px;
  border: 1px solid #e2e2e2;
}

#admins_page .title_content_box .content_box form {
  width: 100%;
}

#admins_page .order_detail_page .title_content_box .content_box td form {
  width: initial;
  display: inline-block;
}

#admins_page .title_content_box .content_box table {
  width: 100%;
}

#admins_page .title_content_box .content_box table th {
  width: 180px;
  text-align: left;
  margin-bottom: 10px;
}

#admins_page .title_content_box .content_box table th span.primary {
  font-size: 12px;
  color: #ff0000;
  margin-left: 8px;
}

#admins_page .title_content_box .content_box table td {
  padding: 10px 0;
}

#admins_page .title_content_box .content_box table td input[type=text],
#admins_page .title_content_box .content_box table td input[type=number] {
  background-color: inherit !important;
  border: 1px solid #e2e2e2 !important;
  border-radius: 0 !important;
  padding: 5px 10px !important;
}

#admins_page .title_content_box .content_box table td input[type=text] {
  width: 400px;
  box-sizing: border-box;
}

#admins_page .title_content_box .content_box table td input[type=checkbox] {
  margin-right: 5px;
  margin-left: 20px;
}

#admins_page .title_content_box .content_box table td label:first-child input[type=checkbox] {
  margin-left: 0px;
}

#admins_page .title_content_box .content_box table td.radio_area label {
  margin-right: 20px;
}

#admins_page .title_content_box .content_box table td select {
  width: 400px;
  padding: 10px;
  font-size: 16px;
  box-sizing: content-box;
  border: 1px solid #e2e2e2;
}

#admins_page .order_detail_page .title_content_box .content_box table td select {
  vertical-align: middle;
}

#admins_page .title_content_box .content_box table td input[type=number] {
  width: 400px;
}

#admins_page .title_content_box .content_box table td .yen {
  margin-left: 10px;
  vertical-align: bottom;
}

#admins_page .title_content_box .content_box table td #thmbnail_img {
  min-width: 200px;
  min-height: 70px;
  border: 1px dashed #e2e2e2;
  vertical-align: bottom;
  margin-right: 20px;
}

#admins_page .title_content_box .content_box table td textarea {
  width: 100%;
  width: 100%;
  box-sizing: border-box;
  height: 10em;
  font-size: 16px;
  padding: 10px;
  resize: none;
  border: 1px solid #e2e2e2;
}

#admins_page .title_content_box .input_btn_area {
  margin: 50px auto;
  text-align: center;
}

#admins_page .title_content_box .input_btn_area button {
  color: #ffffff;
  background-color: #373239;
  border-radius: 0;
  box-shadow: none;
  padding: 10px 50px;
  letter-spacing: 0.5em;
}

#admins_page #item_edit_page #add_form td button,
#admins_page #item_edit_page #update_form td button {
  width: auto;
}

/************************
商品情報編集(管理画面)
************************/
#admins_page .title_content_box .content_box form .update_item_btn_area {
  margin: 50px auto;
  text-align: center;
}

#admins_page .title_content_box .content_box form .update_item_btn_area button {
  color: #ffffff;
  background-color: #373239;
  border-radius: 0;
  box-shadow: none;
  padding: 10px 50px;
  letter-spacing: 0.5em;
}

/************************
会員情報一覧
************************/
#admins_page .title_content_box .content_box form .search_box dd input {
  background-color: inherit;
}

#admins_page .member_list_table .sort_add_area {
  margin-bottom: 10px;
  display: flex;
}

#admins_page .member_list_table .sort_add_area .add_member_btn {
  background-color: #e5767c;
  border: none;
  color: #ffffff;
  padding: 8px 15px;
  margin-left: auto;
}

#admins_page .member_list_table .sort_add_area .add_member_btn span {
  background-color: #ffffff;
  color: #e5767c;
  border-radius: 50%;
  padding: 1px 5px;
  margin-right: 7px;
}

#admins_page .member_list_table table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

#admins_page .member_list_table table th,
#admins_page .member_list_table table td {
  border: 1px solid #373239;
}

#admins_page .member_list_table table .withdrawal th,
#admins_page .member_list_table table .withdrawal td {
  background-color: #969696;
}

#admins_page .member_list_table table th {
  background-color: #e0eaf5;
  padding: 20px 0;
}

#admins_page .member_list_table table th.address_1_item {
  width: 90px;
}

#admins_page .member_list_table table th.output_item {
  width: 100px;
}

#admins_page .member_list_table table th.edit_delete_item {
  width: 100px;
}

#admins_page .member_list_table table td {
  padding: 10px 0;
}

#admins_page .member_list_table table td button {
  background-color: inherit;
  border: none;
  display: block;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.2em;
  margin: 0 auto;
  padding: 5px 15px;
}

#admins_page .member_list_table table td button:hover {
  background-color: #e2e2e2;
}

#admins_page .member_list_table table td button.output_btn {
  color: #ffffff;
  background-color: #373239;
}

#admins_page .member_list_table table td button.delete_btn {
  color: #ff0000;
}

/************************
会員新規登録
会員編集
************************/
#admins_page .infomation_table_section .content_box {
  padding: 0;
  border: 1px solid #969696;
  border-top: none;
  border-bottom: none;
}

#admins_page .infomation_table_section .content_box table {
  border-collapse: collapse;
}

#admins_page .infomation_table_section .content_box table th {
  background-color: #e6e6e6;
  padding-left: 20px;
  width: 180px;
}

#admins_page .infomation_table_section .content_box table td {
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

#admins_page .infomation_table_section .content_box table td:after {
  content: "";
  width: 95%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 1px dashed #b1b1b1;
}

#admins_page .infomation_table_section .content_box table tr.last_child td:after {
  content: "";
  border-bottom: none;
}

#admins_page .infomation_table_section .content_box table th {
  border-bottom: 1px solid #969696;
}

#admins_page .infomation_table_section .content_box table th span {
  font-size: 12px;
  color: #ff0000;
  margin-left: 10px;
}

#admins_page .infomation_table_section .content_box table tr.last_child td {
  border-bottom: 1px solid #969696;
}

#admins_page .infomation_table_section .content_box table td span {
  width: 50px;
  display: inline-block;
}

#admins_page .infomation_table_section .content_box table td.name_en span {
  width: 130px;
  display: inline-block;
}

#admins_page .infomation_table_section .content_box table td input {
  margin-right: 40px;
}

#admins_page .infomation_table_section .content_box table td.postal_code_input_area span {
  width: 30px;
  display: inline-block;
}

#admins_page .infomation_table_section .content_box table td.postal_code_input_area button {
  color: #ffffff;
  background-color: #373239;
  border-radius: 0;
  box-shadow: none;
  padding: 10px 20px;
  letter-spacing: 0.2em;
  border: none;
}

#admins_page .infomation_table_section .content_box table td.address_input_area span {
  width: 150px;
  display: inline-block;
}

#admins_page .infomation_table_section .add_member_btn_area {
  text-align: center;
  margin-top: 50px;
}

#admins_page .order_detail_page .infomation_table_section .add_member_btn_area {
  display: inline-block;
  margin-top: 0;
  margin-left: 40px;
}

#admins_page .infomation_table_section .add_member_btn_area button {
  color: #ffffff;
  background-color: #373239;
  border-radius: 0;
  box-shadow: none;
  padding: 10px 50px;
  letter-spacing: 0.5em;
}

#admins_page .order_detail_page .infomation_table_section .black_btn_area {
  display: inline-block;
  margin-top: 0;
  margin-left: 40px;
}

#admins_page .infomation_table_section .black_btn_area button {
  color: #ffffff;
  background-color: #373239;
  border-radius: 0;
  box-shadow: none;
  padding: 10px 50px;
  letter-spacing: 0.5em;
}

/************************
管理者一覧
************************/
#admins_page .admin_list_table .sort_add_area {
  margin-bottom: 10px;
  display: flex;
}

#admins_page .admin_list_table .sort_add_area .add_admin_btn {
  background-color: #e5767c;
  border: none;
  color: #ffffff;
  padding: 8px 15px;
  margin-left: auto;
}

#admins_page .admin_list_table .sort_add_area .add_admin_btn span {
  background-color: #ffffff;
  color: #e5767c;
  border-radius: 50%;
  padding: 1px 5px;
  margin-right: 7px;
}

#admins_page .admin_list_table td {
  padding: 10px 0;
}

#admins_page .admin_list_table td .delete_btn {
  color: #ff0000;
}

#admins_page table.list_table td.edit_delete_area {
  width: 100px;
}

/************************
お知らせ一覧
************************/
#admins_page table.list_table td {
  padding: 10px;
}

#admins_page table.list_table td.checkbox_area {
  width: 40px;
}

#admins_page table.list_table td.title_area {
  text-align: left;
}

#admins_page table.list_table td.insert_datetime_area {
  width: 200px;
}

#admins_page table.list_table td.status_area {
  width: 100px;
}

/************************
お知らせ詳細
************************/
/************************
CKエディタ
************************/
.ck-editor__editable_inline {
  min-height: 400px;
}

/************************
受注・配送一覧画面 add 20211117
************************/
#admins_page #order_list_page .list_area table th:nth-child(1) {
  width: 130px;
}

#admins_page #order_list_page .list_area table th:nth-child(2),
#admins_page #order_list_page .list_area table th:nth-child(7),
#admins_page #order_list_page .list_area table th:nth-child(8) {
  width: 95px;
}

#admins_page #order_list_page .list_area table th:nth-child(6) {
  width: 80px;
}

#admins_page #order_list_page .list_area table td:nth-child(2),
#admins_page #order_list_page .list_area table td:nth-child(6),
#admins_page #order_list_page .list_area table td:nth-child(7),
#admins_page #order_list_page .list_area table td:nth-child(8) {
  text-align: center;
}

#admins_page #order_list_page .list_area table td:nth-child(8) button {
  border: none;
  background-color: transparent;
  color: var(--admin-default-accent-color);
  font-size: 1em;
}

/************************
メールマガジン一覧画面 add 20211129
************************/
#admins_page #member_list_page .list_area table th:nth-child(2) {
  width: 15%;
}

/************************
一般ページ/　#public_page
************************/
/************************
共通部分
************************/
#public_page {
  /* font-family: "游明朝", YuMincho, "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif; */
  /* font-family: '丸ゴシック－Ｍ', 'ヒラギノ丸ゴ Pro', 'Hiragino Maru Gothic Pro', 'ヒラギノ角ゴ Pro W3',
    'Hiragino Kaku Gothic Pro', 'HG丸ｺﾞｼｯｸM-PRO', 'HGMaruGothicMPRO'; */
  font-family: "Hiragino Mincho ProN W3", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "游明朝", "YuMincho", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
  color: #000000;
  font-weight: 600;
  line-height: 1.5;
}

#public_page .subline_1200 {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

#public_page .subline_1000 {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

#public_page .subline_500 {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

#public_page .breadcrumb a {
  color: inherit;
  text-decoration: none;
}

#public_page .breadcrumb span {
  margin-right: 10px;
  cursor: pointer;
  text-decoration: underline;
}

#public_page .breadcrumb span.nolink {
  cursor: inherit;
  text-decoration: none;
}

#public_page .flex_1 {
  flex: 1;
}

#public_page input[type=password] {
  font-family: sans-serif;
}

/************************
ページング
************************/
#public_page .paging_area {
  margin: 0;
}

#public_page .paging_area button {
  border: none;
  margin-left: 10px;
}

/* 一般画面：共通：ページ選択ボタン：現在選択されているページ  --山下追記コメント */
#public_page .paging_area .active {
  background-color: var(--public-default-accent-color);
}

/* 一般画面：共通：ページ選択ボタン：マウスカーソルが合わさっている時  --山下追記コメント */
#public_page .paging_area button:hover {
  background-color: var(--public-default-accent-color);
  color: #ffffff;
}

/* 一般画面：共通：ページ選択ボタン：前へボタン&次へボタン  --山下追記コメント */
#public_page .paging_area .pre_btn,
#public_page .paging_area .next_btn {
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--public-default-accent-color);
  color: #ffffff;
}

/************************
ヘッダー
************************/
/* 一般画面：ヘッダー  --山下追記コメント */
#public_page header {
  background-color: var(--header-background-color);
  color: #fff;
  padding: 20px 20px;
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  z-index: 101;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 80px;
  height: 94px;
  /* border-top: solid 5px var(--header-border-color); */
  border-bottom: solid 5px var(--header-border-color);
}

#public_page header .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#public_page header .header .header_logo {
  position: absolute;
  /* top: -10px; */
  left: 50%;
  transform: translate(-50%);
}

#public_page header .header .header_logo img {
  /* width: 150px; */
  width: 250px;
  height: auto;
  cursor: pointer;
}

#public_page header .left_area .menu_toggle_btn {
  position: relative;
  height: 30px;
  width: 30px;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
}

/* 一般画面：ヘッダー：ハンバーガーメニューボタン  --山下追記コメント */
#public_page header .left_area .menu_toggle_btn span {
  position: absolute;
  left: 0;
  height: 3px;
  width: 30px;
  background-color: var(--hamburger-menu-link-btn-color);
  border-radius: 2px;
  display: inline-block;
  box-sizing: border-box;
}

#public_page header .left_area .menu_toggle_btn span:nth-of-type(1) {
  top: 2px;
}

#public_page header .left_area .menu_toggle_btn span:nth-of-type(2) {
  top: 12px;
}

#public_page header .left_area .menu_toggle_btn span:nth-of-type(3) {
  top: 22px;
}

#public_page header .left_area .header_sns {
  position: relative;
  /* margin-left: 30px; */
  width: 200px;
  height: 30px;
  display: inline-block;
  top: -5px;
}

#public_page header .left_area .header_sns .sns_logo_box {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-left: 30px;
}

#public_page header .left_area .header_sns img {
  width: 25px;
}

#public_page header .left_area .header_sns a {
  margin-left: 20px;
}

#public_page header .right_area {
  margin-left: auto;
}

#public_page header .right_area button {
  border: none;
  background-color: transparent;
  color: #ffffff;
  margin: 0 8px;
  text-align: center;
  position: relative;
}

/* 一般画面：ヘッダー：お気に入りボタン&買い物カゴボタン：カウントバッジ  --山下追記コメント */
#public_page header .right_area button .badge {
  position: absolute;
  /* background-color: #A14213; */
  background-color: var(--header-cnt-badge-color);
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 25px;
  top: -10px;
  right: 5px;
}

#public_page header .right_area button img {
  height: auto;
  width: 57px;
}

#public_page header .subline_1200 {
  position: relative;
}

#public_page header .login_btn_area {
  position: absolute;
  bottom: -90px;
  right: 0;
}

#public_page .login_btn_area button {
  border: none;
  padding: 15px 15px;
  color: #ffffff;
}

/* 一般画面：ヘッダー：新規会員登録ボタン  --山下追記コメント */
#public_page .login_btn_area button:nth-of-type(1) {
  margin-right: 20px;
  background-color: var(--member-menu-btn-color);
  border: solid 1px var(--member-menu-btn-color);
}

/* 一般画面：ヘッダー：ログインボタン  --山下追記コメント */
#public_page .login_btn_area button:nth-of-type(2) {
  padding: 15px 25px;
  background-color: #fff;
  color: var(--member-menu-btn-color);
  border: solid 1px var(--member-menu-btn-color);
}

#public_page header .member_menu_btn_area {
  position: absolute;
  bottom: -90px;
  right: 0;
}

#public_page .member_menu_btn_area button {
  border: none;
  padding: 10px 15px;
  color: #ffffff;
}

/* 一般画面：ヘッダー：ログイン後ユーザー名表示ボタン  --山下追記コメント */
#public_page .member_menu_btn_area button.member_menu_btn {
  padding: 10px 30px 10px 15px;
  position: relative;
  background-color: var(--member-menu-btn-color);
}

#public_page .member_menu_btn_area button.member_menu_btn .arrow {
  position: absolute;
  display: inline-block;
  right: 7px;
  top: calc(50% - 7px);
  width: 7px;
  height: 7px;
  border-bottom: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
  transform: rotate(45deg);
}

#public_page .member_menu_btn_area .submenu_area {
  position: absolute;
  color: #000000;
  right: 0;
  top: 110%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s;
}

#public_page .member_menu_btn_area .submenu_area.active {
  max-height: 100vh;
  transition: max-height 1s;
}

#public_page .member_menu_btn_area .submenu_area ul {
  list-style: none;
  padding: 0;
  text-align: center;
  border: 1px solid #a69477;
  width: 11em;
  position: relative;
  background-color: #ffffff;
}

#public_page .member_menu_btn_area .submenu_area ul::before,
#public_page .member_menu_btn_area .submenu_area ul::after {
  content: "";
  position: absolute;
}

#public_page .member_menu_btn_area .submenu_area ul::before {
  top: -10px;
  right: 10px;
  border-style: solid;
  border-color: transparent transparent #a69477 transparent;
  border-width: 0 10px 10px 10px;
}

#public_page .member_menu_btn_area .submenu_area ul::after {
  top: -9px;
  right: 11px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  border-width: 0 9px 9px 9px;
  z-index: 10;
}

#public_page .member_menu_btn_area .submenu_area ul li {
  padding: 20px 0;
  cursor: pointer;
  border-bottom: 1px solid #dbd4c9;
}

#public_page .member_menu_btn_area .submenu_area ul li:last-child {
  border-bottom: none;
}

#public_page .member_menu_btn_area .submenu_area ul li:hover {
  background-color: #dbd4c9;
}

/************************
サイドメニュー
************************/
/* 一般画面：ハンバーガーメニュー  --山下追記コメント */
#public_page #sidemenu_area {
  display: block;
  width: 400px;
  height: 100%;
  position: fixed;
  left: -100%;
  /* background-color: rgba(104,157,56,.9); */
  background-color: var(--hamburger-menu-background-color);
  z-index: 101;
  transition: left 0.5s;
  background-repeat: no-repeat;
  background-size: 100px;
  background-position: center bottom 40px;
}

#public_page #sidemenu_area.active {
  left: 0;
}

#public_page #sidemenu_area .close_btn {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 20px;
  width: 30px;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
}

#public_page #sidemenu_area .close_btn span {
  position: absolute;
  left: 0;
  height: 3px;
  width: 30px;
  background-color: #ffffff;
  border-radius: 2px;
  display: inline-block;
  box-sizing: border-box;
  top: 15px;
}

#public_page #sidemenu_area .close_btn span:nth-of-type(1) {
  transform: rotate(45deg);
}

#public_page #sidemenu_area .close_btn span:nth-of-type(2) {
  transform: rotate(-45deg);
}

#public_page #sidemenu_area .menu_area {
  margin-top: 3vw;
  margin-bottom: 3vw;
}

#public_page #sidemenu_area .menu_area ul {
  color: #ffffff;
  list-style: none;
  padding: 0;
}

#public_page #sidemenu_area .menu_area ul li {
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #ffffff;
  cursor: pointer;
}

/* 一般画面：ハンバーガーメニュー：リンクホバー時  --山下追記コメント */
#public_page #sidemenu_area .menu_area ul li:hover {
  color: #ffffff;
  background-color: var(--hamburger-menu-hover-color);
}

#public_page #sidemenu_area .menu_area ul li:last-child {
  border-bottom: none;
}

#public_page #sidemenu_area .search_login_btn_area {
  text-align: center;
  width: 70%;
  margin: auto;
}

#public_page #sidemenu_area .search_area input[name=search_name] {
  padding: 15px 10px;
  width: 100%;
  box-sizing: border-box;
  background-size: 2em;
  background-repeat: no-repeat;
  background-position: right 10px center;
  border: none;
}

#public_page #sidemenu_area .search_area button {
  border: none;
  background-color: var(--hamburger-menu-btn-color);
  color: #ffffff;
  padding: 10px 20px;
  width: 100%;
  margin-top: 10px;
}

#public_page #sidemenu_area .login_btn_area {
  margin-top: 30px;
}

#public_page #sidemenu_area .login_btn_area button {
  padding: 15px 15px;
  width: 9.75em;
  border: none;
}

#public_page #sidemenu_area .login_btn_area button.login_btn {
  color: var(--hamburger-menu-btn-color);
}

#public_page #sidemenu_area .login_btn_area button:nth-child(1) {
  background-color: var(--hamburger-menu-btn-color);
}

#public_page #sidemenu_area .logout_btn_area {
  margin-top: 30px;
}

#public_page #sidemenu_area .logout_btn_area button {
  padding: 10px 15px;
  width: 9em;
}

#public_page #sidemenu_area .logout_btn_area button.logout_btn {
  padding: 10px 20px;
  border: none;
  color: #ffffff;
  background-color: var(--hamburger-menu-btn-color);
}

/************************
トップページ
************************/
#public_page .slick-slider {
  font-size: 0;
  overflow-x: hidden;
  padding-top: 94px;
}

#public_page .slider_item {
  height: auto;
  background-size: cover;
}

.slick-slide img {
  width: 100%;
}

#public_page article {
  /* margin-top: 60px; */
  padding-top: 60px;
  background-color: var(--background-color);
  color: var(--default-text-color);
}

#public_page article .col_2_wrap {
  display: flex;
}

#public_page article .col_2_wrap aside.sidebar {
  width: 200px;
  padding-bottom: 120px;
}

#public_page article .col_2_wrap aside.sidebar .search_area {
  margin-bottom: 60px;
}

#public_page article .col_2_wrap aside.sidebar input[name=search_name] {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  background-size: 2em;
  background-repeat: no-repeat;
  background-position: right 10px center;
}

/* 一般画面：サイドバー：検索ボタン  --山下追記コメント */
#public_page article .col_2_wrap aside.sidebar .search_area button {
  border: none;
  background-color: var(--sidebar-search-btn-color);
  color: #ffffff;
  padding: 8px 20px;
  width: 100%;
  margin-top: 10px;
}

/* 一般画面：サイドバー：タイトル：「商品検索」  --山下追記コメント */
#public_page article .col_2_wrap aside.sidebar .search_area h2 {
  font-size: 20px;
  font-weight: bold;
  box-sizing: border-box;
  padding: 15px 10px 15px 0px;
  /* border: solid 1px var(--sidebar-search-btn-color); */
  margin-bottom: 0;
}

#public_page article .col_2_wrap aside.sidebar .menu_list2 h2 {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background-color: var(--accents-color);
  box-sizing: border-box;
  padding: 15px 10px;
  border: 1px solid var(--accents-color);
  margin-bottom: 0;
  position: relative;
}

#public_page article .col_2_wrap aside.sidebar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#public_page article .col_2_wrap aside.sidebar .menu_list h2 {
  position: relative;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  background-color: var(--sidebar-category-border-color);
  box-sizing: border-box;
  padding: 20px 10px;
  border: 1px solid var(--sidebar-category-border-color);
  margin-bottom: 0;
}

#public_page article .col_2_wrap aside.sidebar .menu_list h2 span {
  position: absolute;
  right: 5px;
  color: #fff;
}

/* 一般画面：サイドバー：コンテンツリスト  --山下追記コメント */
#public_page article .col_2_wrap aside.sidebar ul li {
  padding: 15px 10px 15px 20px;
  border-left: solid 2px var(--sidebar-category-border-color);
  border-right: solid 2px var(--sidebar-category-border-color);
  border-bottom: dashed 1px #ccc;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
}

/* 一般画面：サイドバー：カテゴリ */
#public_page article .col_2_wrap aside.sidebar .menu_list ul li {
  color: var(--sidebar-category-text-color);
  background-color: var(--sidebar-category-background-color);
}

/* 一般画面：サイドバー：コンテンツリスト：一番最後のli要素  --山下追記コメント */
#public_page article .col_2_wrap aside.sidebar ul li:nth-last-child(1) {
  border-bottom: solid 2px var(--sidebar-category-border-color);
}

#public_page article .col_2_wrap aside.sidebar ul li p {
  margin: 0;
}

#public_page article .col_2_wrap aside.sidebar .menu ul li p {
  font-weight: bold;
}

#public_page article .col_2_wrap aside.sidebar .menu_list2 {
  margin-top: 20px;
  margin-bottom: 50px;
}

/* 一般画面：サイドバー：タイトル：コンテンツ  --山下追記コメント */
#public_page article .col_2_wrap aside.sidebar .menu_list2 h2 {
  font-size: 16px;
  color: var(--sidebar-contents-text-color);
  background-color: var(--background-color);
  border-bottom: solid 4px var(--sidebar-contents-bar-base-color);
  border-left: none;
  border-right: none;
  border-top: none;
  position: relative;
  padding: 20px 0px;
}

/* 一般画面：サイドバー：タイトル：コンテンツサブタイトル  --山下追記コメント */
#public_page article .col_2_wrap aside.sidebar .menu_list2 h2 span {
  position: absolute;
  right: 5px;
  color: var(--sidebar-contents-color);
}

/* 一般画面：サイドバー：タイトル：コンテンツ：下線部設定  --山下追記コメント */
#public_page article .col_2_wrap aside.sidebar .menu_list2 h2:after {
  content: "";
  display: block;
  line-height: 0;
  overflow: hidden;
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 30%;
  border-bottom: 4px solid var(--sidebar-contents-color);
}

#public_page article .col_2_wrap aside.sidebar .menu_list2 ul li {
  border: none;
}

#public_page article .col_2_wrap aside.sidebar .menu_list3 a {
  display: block;
  cursor: pointer;
}

#public_page article .col_2_wrap aside.sidebar .menu_list3 a:hover {
  opacity: 0.8;
}

#public_page article .col_2_wrap aside.sidebar .menu_list3 {
  margin-top: 10px;
}

#public_page article .col_2_wrap aside.sidebar .menu_list3 img {
  width: 100%;
  max-width: 200px;
  height: auto;
}

#public_page article .col_2_wrap aside.sidebar ul li span::after {
  content: "";
  position: absolute;
  width: calc(100% + 10px);
  border-bottom: 10px solid rgba(153, 188, 184, 0.9);
  bottom: 0;
  left: -5px;
  z-index: -1;
}

#public_page article .col_2_wrap main {
  flex: 1;
  padding-left: 40px;
}

/* 一般画面：タイトル：ご挨拶  --山下追記コメント */
#public_page article .col_2_wrap h2 .flex_1 {
  margin-top: 0;
  font-size: 35px;
  font-weight: 700;
  text-align: left;
  color: var(--top-h2-greeting-title-color);
}

/* 一般画面：タイトル：おすすめ商品・商品一覧バー  --追記コメント */
#public_page article .col_2_wrap .list_area h2.h2_title {
  font-size: 25px;
  color: var(--top-h2-title-text-color);
  padding: 10px;
  border-left: 5px solid var(--top-h2-title-border-left-color);
  border-bottom: 3px solid var(--top-h2-title-border-bottom-color); /* 下線 */
  background-color: var(--top-h2-title-background-color); /* 背景色 */
  margin-top: 0;
  font-weight: 700;
  text-align: left;
}

/* 一般画面：spanタイトル：ご挨拶  --山下追記コメント */
#public_page article .col_2_wrap .list_area h2.h2_title span {
  font-size: 15px;
  color: var(--top-category-list-area-english);
  margin-left: 20px;
  vertical-align: middle;
  font-weight: normal;
}

#public_page article .col_2_wrap .category_list_area h2.h2_title {
  position: relative;
  font-size: 20px;
}

#public_page article .col_2_wrap .category_list_area h2.h2_title span {
  font-size: 15px;
  font-weight: 400;
  color: var(--top-category-list-area-english);
  margin-left: 20px;
  vertical-align: middle;
}

#public_page article .col_2_wrap .category_list_area h2:after {
  content: "";
  display: block;
  line-height: 0;
  overflow: hidden;
  position: absolute;
  top: 20px;
  left: 35%;
  width: 65%;
  border-bottom: 3px solid #e4e4e4;
}

/* 一般画面：spanタイトル  --山下追記コメント */
#public_page .shop_introduce_area h2 span {
  color: var(--top-h2-greeting-title-en-color);
  margin: 0 2px;
  font-size: 16px;
  padding: 3px 6px 3px 5px;
  vertical-align: middle;
  font-weight: normal;
}

#public_page .top_main_content section {
  margin-bottom: 100px;
}

#public_page .recommend_item_list_area .recommend_item_card {
  display: inline-block;
  max-width: calc(25% - 10px);
  width: calc(15vw - 10px);
  margin: 10px 5px;
  vertical-align: top;
  cursor: pointer;
}

#public_page .recommend_item_list_area .recommend_item_card.big_card {
  max-width: calc(33.3333333333% - 10px);
  width: calc(20vw - 10px);
}

#public_page .recommend_item_list_area .recommend_item_card .thumbnail_area {
  width: 100%;
  height: calc(15vw - 10px);
  background-position: center;
  background-size: contain;
  background-color: #e6e6e6;
  background-repeat: no-repeat;
}

#public_page .recommend_item_list_area .recommend_item_card.big_card .thumbnail_area {
  width: 100%;
  height: calc(20vw - 10px);
}

#public_page .recommend_item_list_area .recommend_item_card .item_name {
  font-size: 16px;
  text-align: left;
}

#public_page .recommend_item_list_area .recommend_item_card .item_price {
  text-align: left;
  color: var(--price-color);
}

#public_page .shop_introduce_area {
  /* margin-top: 100px; */
}

#public_page .shop_introduce_area .wrap {
  /* background-color: #EDEAE4; */
  background-color: var(--introduce-background-color);
  padding: 20px;
  color: var(--introduce-text-color);
}

#public_page .shop_introduce_area h2 {
  /* text-align: center; */
  border-bottom: 1px dashed var(--introduce-dash-color);
  padding-bottom: 15px;
  margin-top: 0;
  font-size: 35px;
  font-weight: 700;
  text-align: left;
  color: var(--top-h2-greeting-title-color);
}

/* 一般画面：spanタイトル  --山下追記コメント */
/* #public_page .shop_introduce_area h2 span{
  color: #FFFFFF;
  background-color: var(--public-default-accent-color);
  margin: 0 2px;
  padding: 3px 6px 3px 5px;
} */
#public_page .shop_introduce_area h2 span:nth-of-type(1) {
  margin-left: 15px;
}

#public_page .shop_introduce_area .text_area {
  margin-top: 30px;
}

#public_page .shop_introduce_area .text_area img {
  float: right;
  border: 8px solid #ffffff;
  width: 40%;
  margin-left: 10px;
}

#public_page .top_main_content .news_area h2 {
  text-align: center;
  font-size: 22px;
  color: var(--public-default-accent-color);
  padding: 20px 0;
  background-color: #ccdedb;
  border-bottom: 3px solid var(--public-default-accent-color);
  background-position: right bottom;
  background-size: contain;
  background-repeat: no-repeat;
}

#public_page .content_html img {
  max-width: 100%;
}

#public_page .news_area .news_card {
  padding: 0 30px;
  cursor: pointer;
}

#public_page .news_area .news_card p {
  margin: 5px 0;
}

#public_page .news_area .news_card .date {
  margin-right: 30px;
  font-size: 14px;
  color: #a69477;
}

#public_page .news_area .news_card .title {
  font-size: 18px;
  color: var(--public-default-accent-color);
}

#public_page .top_main_content .news_area .news_card .content_html * {
  display: inline-block;
}

/* 一般画面：商品一覧へボタン  --山下追記コメント */
#public_page .btn_type_1 {
  position: relative;
  border: none;
  padding: 13px 60px;
  display: block;
  margin: 0 auto;
  background-color: var(--item-list-link-btn-color);
  color: #ffffff;
  cursor: pointer;
}

#public_page .btn_type_1::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid #ffffff;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  right: 7px;
  top: calc(50% - 5px);
}

#public_page .top_main_content .news_area button {
  margin-top: 50px;
}

#public_page .item_list_area .item_card {
  display: inline-block;
  max-width: calc(25% - 10px);
  width: calc(15vw - 10px);
  margin: 10px 5px;
  vertical-align: top;
  cursor: pointer;
}

#public_page .item_list_area .item_card .thumbnail_area {
  width: 100%;
  height: calc(15vw - 10px);
  background-position: center;
  background-size: contain;
  background-color: #e6e6e6;
  background-repeat: no-repeat;
}

#public_page .item_list_area .item_card .item_name {
  font-size: 16px;
}

#public_page .item_list_area .item_card .item_price {
  text-align: left;
  color: var(--price-color);
}

#public_page .category_card {
  display: inline-block;
  width: calc(33.3333333333% - 20px);
  margin: 10px;
  margin-right: 10px;
  background-repeat: no-repeat;
  background-size: 60px;
  background-position: right 30px center;
  cursor: pointer;
}

#public_page .category_card:nth-child(3) {
  margin-right: 0;
}

#public_page .category_card span {
  font-size: 22px;
  position: relative;
  margin-left: 10px;
  letter-spacing: 3px;
}

#public_page .category_card span::after {
  content: "";
  position: absolute;
  width: calc(100% + 10px);
  border-bottom: 10px solid rgba(153, 188, 184, 0.9);
  bottom: 0;
  left: -5px;
  z-index: -1;
}

#public_page .category_card img {
  width: 100%;
}

/************************
フッター
************************/
#public_page footer {
  background-color: var(--footer-top-background-color);
  color: var(--footer-top-text-color);
  padding-top: 40px;
}

#public_page footer .footer_menu {
  display: flex;
  align-items: center;
}

#public_page footer .footer_menu ul {
  list-style: none;
  padding: 0;
  margin-right: 30px;
}

#public_page footer .footer_menu ul li {
  display: inline-block;
  margin: 0 20px;
  cursor: pointer;
}

#public_page footer .footer_menu .media_area span {
  background-color: #ffffff;
  color: var(--public-default-accent-color);
  padding: 5px 7px;
  margin-right: 10px;
}

#public_page footer .footer_menu .media_area img {
  height: 25px;
  vertical-align: middle;
  margin: 0 10px;
  padding-bottom: 5px;
  cursor: pointer;
}

#public_page footer .contact_infomation_area {
  display: flex;
  text-align: center;
  justify-content: center;
}

#public_page footer .contact_infomation_area .telnumber_area {
  margin: 0 40px;
}

#public_page footer .contact_infomation_area p {
  margin: 0;
}

/* 一般画面：フッター：電話番号  --山下追記コメント */
#public_page footer .contact_infomation_area .telnumber_area .telnumber {
  background-position: left center;
  background-size: 28px;
  background-repeat: no-repeat;
  font-size: 30px;
  padding-left: 40px;
  text-align: left;
  padding-top: 5px;
  margin: 0;
  color: var(--footer-tel-number-color);
  font-weight: bold;
}

#public_page footer .contact_infomation_area .mailform_area {
  margin: 0 40px;
}

#public_page footer .contact_infomation_area .mailform_area .mailform {
  margin-top: 15px;
}

/* 一般画面：フッター：コチラからボタン  --山下追記コメント */
#public_page .btn_type_2 {
  position: relative;
  border: 1px solid var(--footer-contact-btn-border-color);
  background-color: initial;
  padding: 15px 100px;
  display: block;
  margin: 0 auto;
  color: var(--footer-contact-btn-text-color);
  cursor: pointer;
  background-color: var(--footer-contact-btn-background-color);
  background-position: 10px center;
  background-size: 30px;
  background-repeat: no-repeat;
}

#public_page .btn_type_2::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid var(--footer-contact-btn-text-color);
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  right: 7px;
  top: calc(50% - 5px);
}

#public_page footer .logo_image_area {
  text-align: center;
}

#public_page footer .logo_image_area img {
  /* margin-top: 0px; */
  margin-top: 40px;
  /* width: 150px; */
  width: 250px;
}

/* 一般画面：フッター：コピーライト  --山下追記コメント */
#public_page footer .copylight_area {
  text-align: center;
  background-color: var(--footer-bottom-background-color);
  color: var(--footer-bottom-text-color);
  padding: 5px 0;
}

#public_page footer .copylight_area p {
  margin: 0;
  font-size: 14px;
}

/* 一般画面：フッター：ご利用ガイド、特定商取引法に基づく標記、プライバシーポリシーリンク表記エリア  --山下追記コメント */
#public_page footer .fp_menu {
  background-color: var(--footer-middle-background-color);
  color: var(--footer-middle-text-color);
  padding: 20px 0;
  margin-top: 40px;
}

#public_page footer .fp_menu ul {
  display: flex;
  list-style: none;
  justify-content: center;
  margin: 0;
}

#public_page footer .fp_menu ul li {
  min-width: 200px;
  text-align: center;
  cursor: pointer;
}

#public_page footer .fp_menu ul li.official_sns {
  cursor: default;
  margin-left: 50px;
}

#public_page footer .fp_menu ul li.official_sns span {
  min-width: 70px;
  padding: 5px 10px;
  background-color: var(--footer-official-sns-background-color);
  color: var(--footer-official-sns-text-color);
}

#public_page footer .fp_menu ul li.official_sns .sns_logo_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

#public_page footer .fp_menu ul li.official_sns img {
  margin-left: 15px;
  width: 25px;
  vertical-align: middle;
}

/************************
１カラム
************************/
#public_page #temp_1col article,
#public_page #temp_2col article {
  padding-top: 114px;
  margin-top: 0;
}

#public_page #temp_1col h1 {
  text-align: center;
  font-size: 32px;
}

#public_page #temp_1col section {
  margin-bottom: 100px;
}

/* 一般画面：ご利用ガイド、プライバシーポリシー：h2タイトル  --山下追記コメント */
#public_page #temp_1col section h2 {
  color: var(--public-h2-text-color);
}

#public_page #temp_1col section ul {
  list-style: decimal;
}

/************************
２カラム
************************/
#public_page #temp_2col h1 {
  text-align: center;
  font-size: 32px;
  overflow-wrap: break-word;
}

#public_page #temp_2col section {
  margin-bottom: 100px;
}

/************************
特定商取引法に基づく表記
************************/
#public_page #commercial_transaction_page table {
  border-collapse: collapse;
  text-align: left;
}

#public_page #commercial_transaction_page th,
#public_page #commercial_transaction_page td {
  border-top: 1px solid #cccccc;
}

#public_page #commercial_transaction_page tr:last-child th,
#public_page #commercial_transaction_page tr:last-child td {
  border-bottom: 1px solid #cccccc;
}

#public_page #commercial_transaction_page th {
  color: #000;
  background-color: #f2f2f2;
  width: 250px;
  padding: 40px 20px;
}

#public_page #commercial_transaction_page td {
  padding: 40px 20px;
}

/************************
プライバシーポリシー
************************/
#public_page #privacy_policy_page .introduction {
  text-align: center;
  width: 60%;
  margin: 60px auto;
}

#public_page #privacy_policy_page section h2 {
  border-bottom: none;
}

/************************
商品一覧
************************/
#public_page #item_list_page .sort_area {
  text-align: right;
}

#public_page #item_list_page .sort_area ul {
  list-style: none;
  display: inline-block;
  padding: 0;
}

#public_page #item_list_page .sort_area ul li {
  display: inline-block;
  padding: 0 15px;
  border-right: 1px solid #000000;
  cursor: pointer;
}

#public_page #item_list_page .sort_area ul li:last-child {
  border-right: none;
  padding-right: 0;
}

#public_page #item_list_page .sort_area ul li.active {
  color: var(--public-default-accent-color);
}

#public_page .list_type_1 .item_card {
  display: inline-block;
  max-width: calc(33.3333333333% - 10px);
  width: calc(20vw - 10px);
  margin: 10px 5px;
  vertical-align: top;
  cursor: pointer;
}

#public_page .list_type_1 .item_card .thumbnail_area {
  width: 100%;
  height: calc(16.6666666667vw - 10px);
  background-position: center;
  background-size: contain;
  background-color: #e6e6e6;
  background-repeat: no-repeat;
}

#public_page .list_type_1 .item_card .item_name {
  font-size: 16px;
}

#public_page .list_type_1 .item_card .item_price {
  text-align: right;
  color: var(--price-color);
}

#public_page .list_type_1 .nostock .thumbnail_area {
  position: relative;
}

#public_page .list_type_1 .nostock .thumbnail_area::after {
  content: "売り切れ中";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  color: #ffffff;
  padding-top: 50%;
  box-sizing: border-box;
}

/************************
商品詳細
************************/
#public_page #item_detail_page .main_image_area {
  width: 100%;
  height: 450px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

#public_page #item_detail_page section {
  margin: 20px 0;
}

#public_page #item_detail_page .item_info_area {
  display: flex;
}

#public_page #item_detail_page .item_info_area .left_content {
  flex: 1;
  margin-right: 20px;
}

#public_page #item_detail_page .item_info_area .left_content p {
  display: inline-block;
  margin: 0;
  word-break: break-all;
}

#public_page #item_detail_page .item_info_area .left_content p.item_serial {
  margin-right: 20px;
  margin-bottom: 10px;
}

#public_page #item_detail_page .item_info_area .left_content p.name {
  display: block;
  margin: 10px 0;
  font-size: 22px;
  overflow-wrap: break-word;
  max-width: 400px;
}

#public_page #item_detail_page .item_info_area .left_content p.name .sold_out {
  color: #ff0000;
  font-size: 12px;
  vertical-align: baseline;
}

#public_page #item_detail_page .item_info_area .left_content p.price {
  display: block;
  margin: 10px 0;
  font-size: 22px;
}

#public_page #item_detail_page .item_info_area .left_content p.postage {
  border: 1px solid #a14213;
  color: #a14213;
  border-radius: 5px;
  padding: 3px 6px;
  font-size: 14px;
}

#public_page #item_detail_page .item_info_area .left_content .item_description {
  margin: 35px 0;
}

#public_page #item_detail_page .item_info_area .right_content {
  width: 300px;
}

#public_page #item_detail_page .item_info_area .right_content .wrap {
  background-color: #edeae4;
  padding: 45px 25px;
  color: #000;
}

#public_page #item_detail_page .item_info_area .right_content table {
  font-size: 14px;
}

#public_page #item_detail_page .item_info_area .right_content th {
  width: 4em;
  text-align: left;
}

#public_page #item_detail_page .item_info_area .right_content .price {
  font-size: 20px;
  color: var(--price-color);
}

#public_page #item_detail_page .item_info_area .right_content .number_select {
  margin: 20px 0;
}

#public_page #item_detail_page .item_info_area .right_content .number_select input {
  margin-left: 30px;
  width: 4rem;
  padding: 5px 0 5px 10px;
}

#public_page #item_detail_page .item_info_area .right_content .btn_area {
  margin-top: 30px;
}

/* 一般画面：商品詳細：デザイン作成ボタン */
#public_page #item_detail_page .item_info_area .right_content .btn_area .create_design_btn {
  background-position: left 33px center;
  background-repeat: no-repeat;
  background-size: 30px;
  font-size: 16px;
  border: none;
  background-color: var(--public-default-accent-color);
  color: #ffffff;
  padding: 20px;
  /* padding-left: 66px; */
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
  margin-bottom: 10px;
}

/* 一般画面：商品詳細：買い物かごに入れるボタン  --山下追記コメント */
#public_page #item_detail_page .item_info_area .right_content .btn_area .insert_cart_btn {
  background-position: left 33px center;
  background-repeat: no-repeat;
  background-size: 30px;
  font-size: 16px;
  border: none;
  background-color: var(--public-default-accent-color);
  color: #ffffff;
  padding: 20px;
  padding-left: 66px;
  width: 100%;
  border-radius: 3px;
  cursor: pointer;
}

#public_page #item_detail_page .item_info_area .right_content .btn_area .soldout_btn {
  background-position: left 30px center;
  background-repeat: no-repeat;
  background-size: 20px;
  font-size: 16px;
  border: 3px solid #a14213;
  background-color: #cccccc;
  color: #a14213;
  padding: 20px;
  padding-left: 40px;
  width: 100%;
  border-radius: 3px;
  cursor: inherit;
}

/* 一般画面：商品詳細：お気に入りに追加・お気に入りから削除ボタン  --山下追記コメント */
#public_page #item_detail_page .item_info_area .right_content .btn_area .insert_favorite_btn {
  background-position: left 50px center;
  background-repeat: no-repeat;
  background-size: 20px;
  background-color: #ffffff;
  border: 2px solid var(--favorite-btn-color);
  color: var(--favorite-btn-color);
  padding: 7px;
  padding-left: 40px;
  width: 100%;
  margin-top: 20px;
  border-radius: 3px;
  cursor: pointer;
}

#public_page #item_detail_page .image_area .item_image_area {
  height: 450px;
  margin-bottom: 20px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

/* 
*************************
保存したデザイン
************************* */
#saved_design_page .design_list {
  margin-bottom: 50px;
}

#saved_design_page p {
  text-align: center;
}

#saved_design_page .design_list .design_list_title {
  font-size: 20px;
  text-align: center;
  background-color: var(--theme-color-1);
  color: white;
}

#saved_design_page .data_grid_wrapper a {
  color: black;
}

#saved_design_page .data_grid_wrapper a:hover {
  text-decoration: underline;
}

/* PDF出力進捗表示 */
#pdfExportStatusBox {
  position: fixed;
  top: 70%;
  left: 50%;
  width: 500px;
  height: 150px;
  border-radius: 10px;
  background-color: #fff;
  z-index: 1001;
  text-align: center;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

#pdfExportStatusBox .status_text {
  color: black;
  font-size: 50px;
  font-weight: bold;
}

#pdfExportStatusBox .status_text span.small {
  color: gray;
  font-size: 15px;
  line-height: 15px;
}

#pdfExportStatusBox .status_text br {
  background-color: black;
  display: block;
  content: "";
  margin: 2px 0;
}

/* 
*************************
お知らせ
************************* */
#public_page #news_list_page .news_card {
  display: flex;
  border-bottom: 1px dashed var(--public-default-accent-color);
  padding: 40px 0;
}

#public_page #news_list_page .news_card:last-child {
  border-bottom: none;
}

#public_page #news_list_page .news_card .left_content {
  flex: 1;
}

#public_page #news_list_page .news_card .left_content .date {
  font-size: 12px;
}

#public_page #news_list_page .news_card .left_content .date .new_tag {
  background-color: #a14213;
  color: #ffffff;
  padding: 2px 5px;
  margin-left: 15px;
}

#public_page #news_list_page .news_card .left_content .title {
  font-size: 18px;
  color: var(--public-default-accent-color);
}

#public_page #news_list_page .news_card .left_content .news_body_html {
  font-size: 14px;
}

#public_page #news_list_page .news_card .left_content .news_detail_link_btn {
  margin-top: 20px;
  border: none;
  color: var(--public-default-accent-color);
  background-color: inherit;
  cursor: pointer;
}

#public_page #news_list_page .news_card .right_content {
  width: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: 20px;
}

#public_page #news_detail_page .date {
  font-size: 14px;
  padding-left: 20px;
}

#public_page #news_detail_page .date .new_tag {
  background-color: #a14213;
  color: #ffffff;
  padding: 2px 5px;
  margin-left: 15px;
  font-size: 12px;
}

#public_page #news_detail_page .title {
  font-size: 20px;
  color: var(--public-default-accent-color);
  padding-left: 20px;
  padding-bottom: 30px;
  border-bottom: 1px dashed var(--public-default-accent-color);
  margin-top: 30px;
}

#public_page #news_detail_page .body_area {
  padding: 20px;
  padding-bottom: 60px;
  border-bottom: 1px dashed var(--public-default-accent-color);
}

#public_page #news_detail_page .news_list_link_btn {
  background-color: var(--public-default-accent-color);
  border: none;
  color: #ffffff;
  padding: 10px 30px;
  display: block;
  margin: 60px auto 0;
}

#public_page #news_detail_page figcaption {
  text-align: center;
}

#public_page #news_detail_page figure.table table {
  width: 100%;
  border-collapse: collapse !important;
  border-spacing: 0 !important;
}

#public_page #news_detail_page figure.table th,
#public_page #news_detail_page figure.table td {
  padding: 15px;
  border: 1px solid #767676;
}

#public_page #news_detail_page figure.table th {
  text-align: center;
}

/************************
お問い合わせ
************************/
#public_page #contact_page .introduction {
  font-size: 14px;
}

#public_page #contact_page table {
  margin: 20px auto;
  width: 100%;
  border-collapse: collapse;
}

#public_page #contact_page table th,
#public_page #contact_page table td {
  border-top: 1px solid #cccccc;
}

#public_page #contact_page table tr:last-child th,
#public_page #contact_page table tr:last-child td {
  border-bottom: 1px solid #cccccc;
}

#public_page #contact_page table th {
  width: 250px;
  background-color: #f2f2f2;
  color: #000;
}

#public_page #contact_page table th span.required {
  color: #ffffff;
  background-color: #ff0000;
  padding: 2px 5px;
  font-size: 14px;
  margin-left: 10px;
}

#public_page #contact_page table th.re_mail_address_input span {
  display: inline-block;
  vertical-align: middle;
}

#public_page #contact_page table td {
  padding: 25px 30px;
}

#public_page #contact_page table td input {
  padding: 5px;
  font-size: 16px;
}

#public_page #contact_page table td .radio_btn_label {
  display: block;
  margin: 10px 0;
}

#public_page #contact_page table td .family_name_area,
#public_page #contact_page table td .first_name_area {
  width: calc(50% - 60px);
  display: inline-block;
}

#public_page #contact_page table td .first_name_area {
  margin-left: 30px;
}

#public_page #contact_page table td .family_name_area span,
#public_page #contact_page table td .first_name_area span {
  margin-right: 10px;
  width: 2em;
  text-align: right;
  display: inline-block;
}

#public_page #contact_page table td .family_name_area input,
#public_page #contact_page table td .first_name_area input {
  width: calc(100% - 2em - 30px);
  border: 1px solid #cbcbcb;
}

#public_page #contact_page table td input[name=mail_address],
#public_page #contact_page table td input[name=re_mail_address] {
  width: 300px;
  margin-right: 15px;
  border: 1px solid #cbcbcb;
}

#public_page #contact_page table td p,
#public_page #contact_page table td p {
  margin: 10px 0 0;
  font-size: 14px;
}

#public_page #contact_page table td textarea {
  width: 100%;
  resize: none;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #cbcbcb;
}

#public_page #contact_page .agree_area {
  text-align: center;
  margin-top: 100px;
}

#public_page #contact_page .agree_area input {
  margin-right: 10px;
}

#public_page #contact_page .agree_area .link_span {
  color: #0000ff;
  text-decoration: underline;
  cursor: pointer;
}

#public_page #contact_page .button_area {
  margin-top: 100px;
  text-align: center;
}

/* 一般画面：お問い合わせ：送信するボタン  --山下追記コメント */
#public_page #contact_page .button_area button {
  width: 150px;
  background-color: var(--public-default-accent-color);
  color: #ffffff;
  padding: 10px;
  border: none;
  margin: 0 20px;
}

#public_page #contact_page .button_area button.desabled {
  background-color: #cccccc;
  pointer-events: none;
}

#public_page #contact_done_page .text_area {
  margin: 60px 0;
}

#public_page #contact_done_page .button_area {
  margin-top: 200px;
  text-align: center;
}

/* 一般画面：お問い合わせ送信完了：トップページに戻るボタン  --山下追記コメント */
#public_page #contact_done_page .button_area button {
  background-color: var(--public-default-accent-color);
  color: #ffffff;
  padding: 10px 30px;
  border: none;
  margin: 0 20px;
}

/************************
新規会員登録申請
************************/
#public_page .form_type_2 form {
  margin-top: 50px;
}

#public_page .form_type_2 form dl {
  margin-top: 40px;
}

#public_page .form_type_2 form dt span.required {
  color: #ffffff;
  background-color: #ff0000;
  padding: 2px 5px;
  font-size: 14px;
  margin-left: 10px;
}

#public_page .form_type_2 form dd {
  margin: 10px 0;
  width: 100%;
}

#public_page .form_type_2 form dd input {
  width: 100%;
  padding: 5px;
  border: 1px solid #cbcbcb;
}

#public_page .form_type_2 .reissue_application_link_btn {
  text-align: center;
  margin-top: 50px;
  cursor: pointer;
  text-decoration: underline;
}

#public_page .form_type_2 .reissue_application_link_btn:hover {
  color: #767676;
}

#public_page .form_type_2 .button_area {
  margin-top: 100px;
  text-align: center;
}

/* 一般画面：新規会員登録：送信するボタン  --山下追記コメント */
#public_page .form_type_2 .button_area button {
  width: 150px;
  background-color: var(--member-menu-btn-color);
  color: #ffffff;
  padding: 10px;
  border: none;
  margin: 0 20px;
}

#public_page .form_type_2 .button_area button.desabled {
  background-color: #cccccc;
  pointer-events: none;
}

/************************
新規会員登録申請完了
************************/
#public_page #signup_application_done_page .link_btn {
  color: #a14213;
  text-decoration: underline;
  cursor: pointer;
}

#public_page #signup_application_done_page .telnumber {
  color: #a14213;
}

#public_page #signup_application_done_page .button_area {
  margin-top: 100px;
  text-align: center;
}

/* 一般画面：新規会員登録申請完了：トップページに戻るボタン  --山下追記コメント */
#public_page #signup_application_done_page .button_area button {
  width: 150px;
  background-color: var(--public-default-accent-color);
  color: #ffffff;
  padding: 10px;
  border: none;
  margin: 0 20px;
}

/************************
新規会員登録
************************/
#public_page .form_type_1 .introduction {
  font-size: 14px;
}

#public_page .form_type_1 table {
  margin: 20px auto;
  width: 100%;
  border-collapse: collapse;
}

#public_page .form_type_1 table th,
#public_page .form_type_1 table td {
  border-top: 1px solid #cccccc;
}

#public_page .form_type_1 table tr:last-child th,
#public_page .form_type_1 table tr:last-child td {
  border-bottom: 1px solid #cccccc;
}

#public_page .form_type_1 table th {
  width: 250px;
  background-color: #343434;
  color: #fff;
}

#public_page .form_type_1 table th span.required {
  color: #ffffff;
  background-color: #ff0000;
  padding: 2px 5px;
  font-size: 14px;
  margin-left: 10px;
  display: inline-block;
}

#public_page .form_type_1 table th.re_password_input span {
  display: inline-block;
  vertical-align: middle;
}

#public_page .form_type_1 table td {
  padding: 25px 30px;
}

#public_page .form_type_1 table td input {
  padding: 5px;
  font-size: 16px;
  border: 1px solid #cbcbcb;
}

#public_page .form_type_1 table td .radio_btn_label {
  display: block;
  margin: 10px 0;
}

#public_page .form_type_1 table td .family_name_area,
#public_page .form_type_1 table td .first_name_area {
  width: calc(50% - 60px);
  display: inline-block;
}

#public_page .form_type_1 table td .first_name_area {
  margin-left: 30px;
}

#public_page .form_type_1 table td .family_name_area span,
#public_page .form_type_1 table td .first_name_area span {
  margin-right: 10px;
  width: 2em;
  text-align: right;
  display: inline-block;
}

#public_page .form_type_1 table td .family_name_area input,
#public_page .form_type_1 table td .first_name_area input {
  width: calc(100% - 2em - 30px);
}

#public_page .form_type_1 table td input[name=password],
#public_page .form_type_1 table td input[name=re_password],
#public_page .form_type_1 table td input[name=mail_address],
#public_page .form_type_1 table td input[name=re_mail_address],
#public_page .form_type_1 table td input[name=birthday] {
  width: 300px;
  margin-right: 15px;
}

#public_page .form_type_1 table td input[name=postal_code] {
  width: 100px;
  margin-left: 15px;
}

#public_page .form_type_1 table td input[name=postal_code].read_only {
  background-color: #edeae4;
}

#public_page .form_type_1 table td input[name=address] {
  width: 80%;
  background-color: #edeae4;
}

#public_page .form_type_1 table td input[name=address_1] {
  width: 80%;
  background-color: #edeae4;
}

#public_page .form_type_1 table td input[name=address_2] {
  width: 80%;
}

#public_page .form_type_1 table td p,
#public_page .form_type_1 table td p {
  margin: 10px 0 0;
  font-size: 14px;
}

#public_page .form_type_1 table td textarea {
  width: 100%;
  resize: none;
  padding: 5px;
  font-size: 16px;
}

#public_page .form_type_1 .agree_area {
  text-align: center;
  margin-top: 100px;
}

#public_page .form_type_1 .agree_area input {
  margin-right: 10px;
}

#public_page .form_type_1 .agree_area .link_span {
  color: #0000ff;
  text-decoration: underline;
  cursor: pointer;
}

#public_page .form_type_1 .button_area {
  margin-top: 100px;
  text-align: center;
}

/* 一般画面：会員情報変更：お客様情報を変更するボタン  --山下追記コメント */
#public_page .form_type_1 .button_area button {
  width: 150px;
  background-color: var(--public-default-accent-color);
  color: #ffffff;
  padding: 10px;
  border: none;
  margin: 0 20px;
}

#public_page .form_type_1 .button_area button.back_btn {
  background-color: #edeae4;
  color: black;
}

#public_page .form_type_1 .button_area button.desabled {
  background-color: #cccccc;
  pointer-events: none;
}

/* 一般画面：会員情報変更：お届け先リストを追加・編集するボタン  --山下追記コメント */
#public_page .form_type_1 td button {
  background-color: var(--public-default-accent-color);
  color: #ffffff;
  padding: 9px 30px;
  border: none;
  margin-left: 20px;
}

#public_page #signup_page .form_type_1 tr:nth-child(6) td button {
  margin-left: 15px;
}

/************************
新規会員登録完了
************************/
#public_page #signup_done_page .link_btn {
  color: #a14213;
  text-decoration: underline;
  cursor: pointer;
}

#public_page #signup_done_page .telnumber {
  color: #a14213;
}

#public_page #signup_done_page .button_area {
  margin-top: 100px;
  text-align: center;
}

/* 一般画面：新規会員登録完了：トップページに戻る  --山下追記コメント */
#public_page #signup_done_page .button_area button {
  width: 150px;
  background-color: var(--public-default-accent-color);
  color: #ffffff;
  padding: 10px;
  border: none;
  margin: 0 20px;
}

/************************
マイページ・会員情報変更
************************/
/* 一般画面：会員情報変更：郵便番号から検索ボタン  --山下追記コメント */
#public_page #member_edit_page .form_type_1 td button {
  background-color: var(--public-default-accent-color);
  color: #ffffff;
  padding: 10px 30px;
  border: none;
}

#public_page #member_edit_page .form_type_1 .button_area button {
  width: 200px;
}

#public_page #member_edit_page .form_type_1 tr:nth-child(4) td button {
  margin-left: 15px;
  padding: 9px 30px;
}

/************************
マイページ・お届け先リスト
************************/
#public_page #temp_1col section.search_add_area {
  margin-top: 50px;
  margin-bottom: 30px;
}

#public_page .search_add_area {
  display: flex;
}

#public_page .search_add_area .left_content input {
  padding: 5px;
  width: 250px;
  border: 1px solid #cbcbcb;
}

#public_page .search_add_area .left_content button {
  margin-left: 10px;
  padding: 6px 30px;
  letter-spacing: 3px;
  border: none;
  color: #ffffff;
  background-color: #a14213;
}

#public_page .search_add_area .right_content {
  margin-left: auto;
}

/* 一般画面：お届け先リスト：新規登録ボタン  --山下追記コメント */
#public_page .search_add_area .right_content button {
  background-size: 15px;
  background-position: left 30px center;
  background-repeat: no-repeat;
  padding: 5px 40px 5px 55px;
  background-color: var(--public-default-accent-color);
  color: #ffffff;
  border: none;
}

#public_page .list_area table {
  width: 100%;
  border-collapse: collapse;
}

#public_page .list_area table th {
  background-color: #edead3;
  border: 1px solid #8c8c8c;
  padding: 10px 0;
  color: #000;
}

#public_page .list_area table td {
  border: 1px solid #8c8c8c;
  padding: 10px 5px;
}

#delivery_address_list_page .list_area table th:nth-child(1),
#delivery_address_list_page .list_area table td:nth-child(1) {
  width: 12%;
}

#delivery_address_list_page .list_area table th:nth-child(2),
#delivery_address_list_page .list_area table td:nth-child(2) {
  width: 20%;
}

#delivery_address_list_page .list_area table th:nth-child(4),
#delivery_address_list_page .list_area table td:nth-child(4) {
  width: 18%;
}

#delivery_address_list_page .list_area table th:nth-child(5),
#delivery_address_list_page .list_area table td:nth-child(5) {
  width: 12%;
}

#public_page .list_area table td.checkbox_box {
  text-align: center;
  width: 50px;
}

#public_page .list_area table td.checkbox_box input {
  margin: 0;
}

#public_page .list_area table td.name_box {
  text-align: center;
}

#public_page .list_area table td.address_box {
  padding-left: 30px;
}

#public_page .list_area table td.telnumber_box {
  text-align: center;
}

#public_page .list_area table td.button_box {
  text-align: center;
  width: 100px;
}

#public_page .list_area table td.button_box {
  padding-top: 20px;
  padding-bottom: 20px;
}

#public_page .list_area table td.button_box button {
  display: block;
  margin: auto;
  background-color: #ffffff;
  padding: 3px 10px;
  border-style: solid;
}

#public_page .list_area table td.button_box button:first-child {
  margin-bottom: 10px;
}

#public_page .list_area table td.button_box button.delete_btn {
  color: #ff0000;
  border-color: #ff0000;
}

#public_page #mailing_list_edit_page .list_area table td.button_box button.delete_btn {
  margin-bottom: 0;
}

#public_page .list_area table td.button_box button.add_btn {
  margin-bottom: 0;
}

#public_page .list_area table td.name_box span.margin_right_10px {
  margin-right: 10px;
}

#public_page .under_menu_area {
  display: flex;
  margin-top: 30px;
}

#public_page .under_menu_area .left_group {
  display: flex;
}

#public_page .under_menu_area .left_group select {
  padding: 10px 5px;
  margin-right: 10px;
  border: 1px solid #cbcbcb;
}

#public_page .under_menu_area .left_group button {
  padding: 10px 25px;
  letter-spacing: 5px;
  color: #ffffff;
  background-color: #373239;
  border: none;
  cursor: pointer;
}

#public_page .under_menu_area .left_group button.add_btn {
  padding: 10px 30px 10px 50px;
  color: #ffffff;
  letter-spacing: 2px;
  background-color: var(--public-default-accent-color);
  border: none;
  cursor: pointer;
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 23px center;
}

#public_page .under_menu_area .right_group {
  margin-left: auto;
}

#public_page .under_menu_area .right_group .paging_area {
  margin: 0;
}

#public_page .under_menu_area .right_group .paging_area button {
  border: none;
  margin-left: 10px;
}

#public_page .under_menu_area .right_group .paging_area .active {
  background-color: var(--public-default-accent-color);
}

#public_page .under_menu_area .right_group .paging_area button:hover {
  background-color: var(--public-default-accent-color);
  color: #ffffff;
}

#public_page .under_menu_area .right_group .paging_area .pre_btn,
#public_page .under_menu_area .right_group .paging_area .next_btn {
  padding-left: 20px;
  padding-right: 20px;
  background-color: var(--public-default-accent-color);
  color: #ffffff;
}

/************************
マイページ・お届け先新規登録
************************/
#public_page #delivery_address_add_page .form_type_1 tr:nth-child(3) td button {
  margin-left: 15px;
}

/************************
マイページ・お気に入り一覧
************************/
#public_page #item_list_page .item_card {
  margin-bottom: 30px;
}

#public_page #item_list_page .item_card .thumbnail_area {
  position: relative;
}

#public_page #item_list_page .item_card .thumbnail_area img {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  z-index: 100;
  cursor: pointer;
}

#public_page #item_list_page .item_card p {
  text-align: left;
}

#public_page #item_list_page .item_card .btn_area {
  text-align: center;
}

/* 一般画面：お気に入り一覧：買い物カゴに入れるボタン  --山下追記コメント */
#public_page #item_list_page .item_card .btn_area button {
  background-position: left 20px center;
  background-repeat: no-repeat;
  background-size: 20px;
  font-size: 14px;
  border: none;
  background-color: var(--public-default-accent-color);
  color: #ffffff;
  padding: 10px 16px;
  padding-left: 40px;
  width: 80%;
  border-radius: 3px;
  cursor: pointer;
  border: 2px solid var(--public-default-accent-color);
}

#public_page #item_list_page .item_card .btn_area button.soldout_btn {
  border: 2px solid #a14213;
  background-color: #cccccc;
  color: #a14213;
  cursor: initial;
  padding-left: 20px;
}

/************************
マイページ・買い物カゴ
************************/
#public_page #order_flow_area .wrap {
  border-top: 1px solid #b3b3b3;
  border-bottom: 1px solid #b3b3b3;
  padding: 30px 0;
}

#public_page #order_flow_area ul {
  list-style: none !important;
  text-align: center;
}

#public_page #order_flow_area ul li {
  display: inline-block;
  margin: 0 20px;
  color: #b3b3b3;
  font-size: 18px;
}

#public_page #order_flow_area ul li.active {
  color: var(--public-default-accent-color);
}

#public_page #order_flow_area ul li .number {
  padding: 2.5px 6px;
  border: 1px solid;
  margin-right: 10px;
}

#public_page #order_flow_area ul li.active .number {
  background-color: var(--public-default-accent-color);
  color: #ffffff;
}

#public_page #order_flow_area ul li .label {
  font-size: 14px;
}

#public_page .list_type_2 .scroll_wrap {
  overflow: auto;
}

#public_page .list_type_2 table {
  width: 100%;
  border-collapse: collapse;
}

#public_page .list_type_2 th {
  background-color: #343434;
  color: #ffffff;
  border: 1px solid #999999;
  padding: 10px 0;
}

#public_page .list_type_2 th.item_name_th {
  width: 70%;
}

#public_page .list_type_2 th.delete_btn_th {
  width: 80px;
}

#public_page .list_type_2 td {
  padding: 10px;
  border: 1px solid #999999;
}

#public_page .list_type_2 td:nth-child(1) {
  min-width: 500px;
}

#public_page .list_type_2 table#additional_shipping_fee_table td {
  min-width: 0;
}

#public_page .list_type_2 td:nth-child(2) {
  min-width: 120px;
}

#public_page .list_type_2 td:nth-child(3) {
  min-width: 100px;
}

#public_page .list_type_2 td:nth-child(4) {
  min-width: 100px;
}

#public_page .list_type_2 td.item_image_name_box .item_info_wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 5%;
  width: 65%;
}

#public_page .list_type_2 td.item_image_name_box div .name {
  margin-top: 0;
  word-break: break-all;
}

#public_page .list_type_2 td.item_image_name_box div .standard {
  overflow-wrap: break-word;
  /* max-width: 370px; */
}

#public_page .list_type_2 td.item_image_name_box div.thumbnail_area {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  float: left;
}

#public_page .list_type_2 td.item_image_name_box div.thumbnail_area img {
  width: 90%;
  max-height: 190px;
}

#public_page .list_type_2 td.nostock div.thumbnail_area div::after {
  content: "売り切れ";
  display: block;
  width: 90%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  color: #ffffff;
  box-sizing: border-box;
}

#public_page .list_type_2 td.shortage div.thumbnail_area div::after {
  content: "在庫不足";
  display: block;
  width: 90%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  color: #ffffff;
  box-sizing: border-box;
}

#public_page .list_type_2 td.price_box {
  text-align: center;
}

#public_page .list_type_2 td.postage_box {
  text-align: center;
}

#public_page .list_type_2 td.quantity_box {
  text-align: center;
  min-width: 80px;
}

#public_page .list_type_2 #additional_shipping_fee_table {
  margin-top: 10px;
  margin-bottom: 50px;
}

#public_page .list_type_2 td.additional_shipping_fee {
  text-align: center;
}

#public_page .list_type_2 td.delete_btn_box {
  text-align: center;
  min-width: 50px;
}

#public_page .list_type_2 td button.delete_btn {
  border: none;
  background-color: initial;
  font-weight: 700;
  text-decoration: underline;
  color: var(--default-text-color);
}

#public_page .list_type_2 .btn_area {
  margin-top: 100px;
  text-align: center;
}

#public_page .list_type_2 .btn_area .item_list_link_btn {
  background-color: #edeae4;
  padding: 15px;
  width: 220px;
  border: none;
  margin: 0 20px;
}

/* 一般画面：買い物カゴ：購入手続きへボタン  --山下追記コメント */
#public_page .list_type_2 .btn_area .order_post_info_link_btn {
  background-color: var(--public-default-accent-color);
  color: #ffffff;
  padding: 15px;
  width: 220px;
  border: none;
  margin: 0 20px;
}

/************************
マイページ・お届け先情報の入力
************************/
#public_page .order_post_info_content .introduction button {
  background-color: var(--public-default-accent-color);
  color: #ffffff;
  padding: 10px 15px;
  border: none;
  margin-left: 20px;
}

#public_page .list_type_3 .order_card_box {
  border: 1px solid #000000;
  padding: 20px;
}

#public_page .list_type_3 .order_card_box h2 {
  border: none !important;
  color: #000000 !important;
  margin-top: 5px;
}

#public_page .list_type_3 .btn_area {
  margin-top: 100px;
  text-align: center;
}

#public_page .list_type_3 .btn_area .cart_list_link_btn {
  background-color: #edeae4;
  padding: 15px;
  width: 220px;
  border: none;
  margin: 0 20px;
}

#public_page .list_type_3 .btn_area .order_info_link_btn {
  background-color: var(--public-default-accent-color);
  color: #ffffff;
  padding: 15px;
  width: 220px;
  border: none;
  margin: 0 20px;
}

#public_page .list_type_3 .order_card {
  margin-bottom: 60px;
}

#public_page .list_type_3 .order_card .post_info {
  margin-bottom: 10px;
  display: flex;
}

#public_page .list_type_3 .order_card h3 {
  display: inline-block;
  margin: 0;
  margin-right: 40px;
}

#public_page .list_type_3 .order_card p {
  display: inline-block;
  margin: 0;
  margin-right: 20px;
}

#public_page .list_type_3 .order_card .postal_code {
  margin-right: 10px;
}

#public_page .list_type_3 .order_card .delete_btn {
  background-color: #ff0000;
  color: #ffffff;
  border: none;
  letter-spacing: 2px;
  margin-left: auto;
  margin-right: 20px;
  padding: 0 14px;
  font-weight: bold;
  /* width: 61px; */
  /* height: 30px; */
  display: block;
}

#public_page .list_type_3 .order_card .item_list {
  border: 1px solid #cccccc;
  padding: 20px;
}

#public_page .list_type_3 .order_card form {
  margin-top: 20px;
  border-bottom: 1px dashed #cbcbcb;
  padding-bottom: 10px;
}

#public_page .list_type_3 .order_card form p {
  width: 100%;
}

#public_page .list_type_3 .order_card form .item_name {
  margin-right: 20px;
  width: 50%;
  display: inline-block;
}

#public_page .list_type_3 .order_card form .item_standard {
  margin-right: 20px;
  width: 24%;
  display: inline-block;
}

#public_page .list_type_3 .order_card form input {
  width: 2em;
  margin-left: 10px;
  text-align: right;
  padding: 0 3px;
  vertical-align: text-bottom;
  border: 1px solid #cbcbcb;
}

/************************
マイページ・お届け先の追加
************************/
#public_page .add_order_post_address_content form {
  margin-bottom: 100px;
}

#public_page .add_address_btn_area {
  margin: 60px 0 40px;
  text-align: center;
}

#public_page .add_address_btn_area button {
  background-color: var(--public-default-accent-color);
  color: #ffffff;
  padding: 15px;
  border: none;
  margin: 0 40px;
}

#public_page .add_form_btn_area {
  margin-top: 80px;
}

#public_page .add_form_btn_area button {
  width: 100%;
  background-color: #a14213;
  background-position: left calc(50% - 8em) center;
  background-repeat: no-repeat;
  background-size: 20px;
  padding: 15px;
  color: #ffffff;
  border: none;
  font-size: 18px;
}

#public_page .btn_area {
  text-align: center;
  margin-top: 150px;
}

#public_page #order_confirm_page .sq-card-wrapper {
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
}

#public_page #order_confirm_page .btn_area {
  margin-top: 100px;
}

#public_page .order_post_info_link_btn {
  background-color: #edeae4;
  padding: 15px;
  width: 220px;
  border: none;
  margin: 0 20px;
  /* margin: 40px auto; */
}

#public_page .add_btn {
  background-color: var(--public-default-accent-color);
  color: #ffffff;
  padding: 15px;
  width: 220px;
  border: none;
  margin: 0 20px;
  /* margin: 40px auto; */
}

#public_page button.disabled {
  background-color: #cccccc;
  pointer-events: none;
}

#public_page #add_order_post_address_page .form_type_1 tr:nth-child(3) td button.postal_num_btn {
  margin-left: 15px;
}

/************************
マイページ・お支払方法の入力
************************/
#public_page .order_post_info_link_btn {
  background-color: #edeae4;
  padding: 15px;
  width: 220px;
  border: none;
  /* margin: 0 40px; */
  margin: 40px auto;
}

#public_page .order_confirm_link_btn {
  background-color: var(--public-default-accent-color);
  color: #ffffff;
  padding: 15px;
  width: 220px;
  border: none;
  margin: 0 40px;
}

/************************
マイページ・入力内容の確認
************************/
#public_page .order_confirm_content h2 {
  color: var(--default-text-color) !important;
  border-bottom: none !important;
}

#public_page .order_card_box .order_card {
  margin-bottom: 40px;
}

#public_page .order_card_box .order_card dt,
#public_page .order_card_box .order_card dd {
  border: 1px solid #999999;
  padding: 10px 20px;
}

#public_page .order_card_box .order_card dt {
  background-color: #edeae4;
  color: #000;
}

#public_page .order_card_box .order_card dt.post_title {
  background-color: #ccdedb;
}

#public_page .order_card_box .order_card dd {
  margin: 0;
  border-top: none;
  border-bottom: none;
}

#public_page .order_card_box .order_card dd:last-child {
  border-bottom: 1px solid #999999;
}

#public_page .order_card_box .order_card dd p {
  margin: 5px 0;
}

#public_page .order_card_box .order_card dd p.post_name {
  margin-top: 15px;
  margin-bottom: 20px;
}

#public_page .order_card_box .order_card dd p.item_info {
  margin-bottom: 20px;
  border-bottom: 1px dashed #cbcbcb;
  padding-bottom: 10px;
}

#public_page .order_card_box .order_card dd span {
  display: inline-block;
  margin-right: 30px;
  text-align: right;
}

#public_page .order_card_box .order_card dd span.item_name {
  width: 30%;
  text-align: left;
  vertical-align: top;
}

#public_page .order_card_box .order_card dd span.standard {
  width: 15%;
}

#public_page .order_card_box .order_card dd span.number {
  width: 10%;
}

#public_page .order_card_box .order_card dd span.price {
  width: 15%;
}

#public_page .pay_type_box {
  margin-top: 60px;
}

#public_page .pay_type_box dl {
  display: flex;
  border-top: 1px solid #999999;
  border-bottom: 1px solid #999999;
}

#public_page .pay_type_box dt {
  width: 30%;
  max-width: 200px;
  background-color: #ccdedb;
  padding: 20px;
}

#public_page .pay_type_box dd {
  flex: 1;
  margin: 0;
  padding: 20px 40px;
}

#public_page .total_price_box {
  margin-top: 60px;
}

#public_page .total_price_box dl {
  display: flex;
  border-top: 1px solid #999999;
  margin: 0;
}

#public_page .total_price_box dl:last-child {
  border-bottom: 1px solid #999999;
}

#public_page .total_price_box dt {
  width: 40%;
  max-width: 350px;
  background-color: #edeae4;
  padding: 20px;
  text-align: center;
  color: #000;
}

#public_page .total_price_box dd {
  flex: 1;
  margin: 0;
  padding: 20px 40px;
  text-align: center;
}

#public_page .total_price_box dd.total_price {
  font-size: 22px;
}

#public_page .order_info_link_btn {
  background-color: #edeae4;
  padding: 15px;
  width: 220px;
  border: none;
  margin: 0 20px;
}

#public_page .payment_btn_area {
  margin-top: 50px;
  text-align: center;
}

#public_page .order_decide_btn {
  background-color: var(--public-default-accent-color);
  color: #ffffff;
  padding: 15px;
  width: 220px;
  border: none;
  margin: 0 20px;
  margin: 0 auto;
}

#public_page .order_decide_btn:disabled {
  /* background-color: #333333; */
}

/************************
マイページ・注文完了
************************/
#public_page #order_completed_page .link_btn {
  color: #a14213;
  text-decoration: underline;
  cursor: pointer;
}

#public_page #order_completed_page .telnumber {
  color: #a14213;
}

#public_page #order_completed_page .button_area {
  margin-top: 100px;
  text-align: center;
}

#public_page #order_completed_page .button_area button {
  width: 150px;
  background-color: var(--public-default-accent-color);
  color: #ffffff;
  padding: 10px;
  border: none;
  margin: 0 20px;
}

#public_page .popup_area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 110;
}

#public_page .popup_area .shadow_wrap {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
}

#public_page .popup_area .popup_content {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 6vw;
  background-color: #ffffff;
  padding: 60px 40px;
  width: 70%;
}

#public_page .popup_area .popup_content h2 {
  margin: 0;
  margin-bottom: 60px;
}

#public_page .popup_area .popup_content table {
  margin: 0;
  margin-bottom: 60px;
}

#public_page .popup_area .btn_area {
  margin-top: 40px;
}

#public_page .popup_area .no_add_btn {
  background-color: #edeae4;
  padding: 15px;
  width: 220px;
  border: none;
  margin: 40px auto;
}

/************************
マイページ・購入履歴
************************/
#public_page #order_history_page .list_area .scroll_wrap {
  overflow: auto;
}

#public_page #order_history_page .list_area span {
  display: block;
}

#public_page #order_history_page .list_area .order_date,
#public_page #order_history_page .list_area .datetime,
#public_page #order_history_page .list_area .order_number {
  text-align: center;
}

#public_page #order_history_page .list_area td:nth-child(1) {
  width: 20%;
  min-width: 160px;
}

#public_page #order_history_page .list_area td:nth-child(2) {
  width: 55%;
  min-width: 500px;
}

#public_page #order_history_page .list_area td:nth-child(3) {
  width: 10%;
  min-width: 100px;
  text-align: center;
}

#public_page #order_history_page .list_area td:nth-child(4) {
  width: 15%;
  text-align: center;
}

#public_page #order_history_page .list_area button {
  min-width: 100px;
  margin: 20px auto;
  display: table;
  border: 1px solid var(--public-default-accent-color);
  background-color: var(--public-default-accent-color);
  color: #fff;
  padding: 10px;
  /* width: 85%; */
}

#public_page #order_history_page .list_area div {
  vertical-align: top;
  margin: 0 15px;
}

#public_page #order_history_page .list_area .thumbnail_area {
  width: 100px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  float: left;
}

#public_page #order_history_page .list_area .thumbnail_area img {
  width: 80%;
}

#public_page #order_history_page .list_area .itemName_area {
  word-break: break-all;
  overflow: hidden;
}

/*
******************************
レスポンシブ対応　2021.11.5 add
****************************** */
@media screen and (max-width: 1200px) {
  /* 一般ぺージ(共通) */
  #public_page .subline_1200 {
    max-width: 100%;
  }
  /* 管理画面(サイドバー) */
  #admins_page aside.sidebar li {
    line-height: 1.5em;
    padding: 15px 8px;
  }
}
@media screen and (max-width: 1024px) {
  /* 管理画面(共通) */
  #admins_page header {
    background-size: 150px;
  }
  #admins_page .box_type_1 th,
  #admins_page .box_type_1 td {
    padding: 20px;
  }
  #admins_page .box_type_1 .content_area table td.flex span.header {
    width: 95px;
  }
  #admins_page .box_type_1 .content_area table td.birthday_box span {
    margin-left: 0;
    margin-top: 7px;
    display: block;
  }
  #admins_page .box_type_2 dd .radio_btn_label {
    margin-right: 20px;
  }
  #admins_page #add_form td button,
  #admins_page #update_form td button {
    margin: 10px 0 0;
  }
  /* 管理画面(TOP) */
  #admins_page #home_page .situation_area {
    margin: 0 25px;
  }
  /* 商品一覧(管理画面) */
  #admins_page #item_list_page .list_area table th:nth-child(3) {
    width: 180px;
  }
  #admins_page #item_list_page .list_area table th:nth-child(8) {
    width: 40px;
  }
  /* 受注・配送管理一覧(管理画面) */
  #admins_page #order_list_page .list_area table th:nth-child(1) {
    width: 95px;
  }
  #admins_page #order_list_page .list_area table th:nth-child(2),
  #admins_page #order_list_page .list_area table th:nth-child(6),
  #admins_page #order_list_page .list_area table th:nth-child(7),
  #admins_page #order_list_page .list_area table th:nth-child(8) {
    width: 60px;
  }
}
@media screen and (max-width: 1000px) {
  /* 一般ぺージ(共通) */
  #public_page .subline_1000 {
    max-width: 95%;
    /* margin: 30px auto; */
  }
  /* ヘッダー(一般) */
  #public_page header {
    /* background-position: center 65px;
    background-size: 55px;
    height: 114px;
    padding-bottom: 15px;
    background-color: #ffffff; */
  }
  #public_page header .header {
    display: block;
  }
  #public_page header .right_area {
    margin: 0 auto;
    text-align: end;
  }
  #public_page header .right_area button.header_menu_btn {
    display: none;
  }
  #public_page header .left_area .header_sns {
    display: none;
  }
  #public_page header .right_area button img {
    height: 16px;
  }
  #public_page header .right_area {
    position: absolute;
    top: -7px;
    right: 0;
  }
  #public_page header .login_btn_area,
  #public_page header .member_menu_btn_area {
    /* bottom: -48px;
    right: -7px; */
  }
  /* フッター(一般) */
  #public_page footer .footer_menu {
    display: block;
  }
  #public_page footer .media_area {
    text-align: end;
  }
  #public_page footer .footer_menu ul {
    margin: 0 auto 30px;
  }
  #public_page footer .footer_menu .media_area span {
    padding: 3px 5px;
  }
  #public_page footer .footer_menu .media_area img {
    height: 18px;
  }
  /* 新規会員登録 */
  #public_page .form_type_1 table span {
    display: block;
  }
  /* お気に入り一覧 */
  #public_page .btn_area {
    margin-top: 80px;
  }
  #public_page #privacy_policy_page .introduction {
    width: 100%;
    text-align: left;
  }
  #public_page #order_history_page .sp_txt {
    display: block;
    margin-left: 10px;
  }
}
@media screen and (max-width: 900px) {
  /* ヘッダー(一般) */
  #public_page .breadcrumb {
    margin-top: 20px;
  }
  /* フッター(一般) */
  #public_page footer .contact_infomation_area .telnumber_area .telnumber {
    /* font-size: 34px; */
  }
  /* 一般ぺージ(TOP) */
  #public_page .category_card {
    width: calc(50% - 60px);
  }
  /* 商品一覧 */
  #public_page .list_type_1 .item_card .thumbnail_area {
    height: calc(17.5vh - 10px);
  }
  /* 買い物カゴ */
  #public_page #order_flow_area .wrap {
    padding: 15px 0;
  }
  #public_page #temp_1col section ul {
    text-align: left;
  }
  #public_page #order_flow_area ul li {
    margin: 10px 0px;
    width: 185px;
  }
}
@media screen and (max-width: 965px) {
  /* ヘッダー(一般)   */
  #public_page header .right_area button {
    font-size: 0.7em;
  }
  #public_page header .left_area .menu_toggle_btn span:nth-of-type(1) {
    top: 19px;
  }
  #public_page header .left_area .menu_toggle_btn span:nth-of-type(2) {
    top: 29px;
  }
  #public_page header .left_area .menu_toggle_btn span:nth-of-type(3) {
    top: 39px;
  }
  #public_page header .right_area button .badge {
    width: 22px;
    height: 20px;
  }
  /* 一般ぺージ(共通) */
  #public_page .breadcrumb {
    /* display: none; */
  }
  #public_page #temp_1col h1,
  #public_page #temp_2col h1 {
    font-size: 25px;
  }
  /* 一般ぺージ(TOP) */
  #public_page article .col_2_wrap {
    flex-wrap: wrap;
  }
  #public_page article .col_2_wrap aside.sidebar {
    width: 100%;
    position: relative;
    order: 2;
  }
  #public_page article .col_2_wrap main {
    width: 100%;
    padding-left: 0;
    position: relative;
    order: 1;
    padding-bottom: 40px;
  }
  #public_page .category_list_area {
    display: none;
  }
  #public_page article .col_2_wrap aside.sidebar .web-banner-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 200px;
    margin: 0 auto;
  }
  #public_page article .col_2_wrap aside.sidebar .web-banner-wrap .menu_list3 {
    /* width: 200px; */
  }
  #public_page .recommend_item_list_area .recommend_item_card,
  #public_page .recommend_item_list_area .recommend_item_card.big_card {
    width: calc(33.3333333333% - 10px);
    max-width: calc(33.3333333333% - 10px);
  }
  #public_page .recommend_item_list_area .recommend_item_card .thumbnail_area,
  #public_page .recommend_item_list_area .recommend_item_card.big_card .thumbnail_area {
    height: calc(23.3333333333vh - 10px);
  }
  #public_page .item_list_area .item_card {
    width: calc(33.3333333333% - 10px);
    max-width: calc(33.3333333333% - 10px);
  }
  #public_page .item_list_area .item_card .thumbnail_area {
    height: calc(23.3333333333vh - 10px);
  }
  /* サイドバー(一般) */
  #public_page article .col_2_wrap aside.sidebar ul li {
    display: inline-block;
    width: calc(50% - 20px);
    margin: 10px;
    padding: 15px;
    background-position: right 15px center;
    background-size: 35px;
    border-bottom: none;
  }
  #public_page article .col_2_wrap aside.sidebar ul li:nth-last-child(1) {
    border-bottom: none;
  }
  /* #public_page article .col_2_wrap aside.sidebar ul li:nth-child(5){
    background-size: 20px !important;
    background-position: right 19px center !important;
  } */
  #public_page article .col_2_wrap aside.sidebar .search_area button {
    padding: 15px 20px;
  }
  /* フッター(一般) */
  #public_page footer .contact_infomation_area {
    display: block;
  }
  #public_page footer .contact_infomation_area .telnumber_area .telnumber {
    /* font-size: 30px; */
    display: table;
    margin: 0 auto;
  }
  #public_page footer .contact_infomation_area .mailform_area .mailform {
    display: table;
    margin: 15px auto;
  }
  #public_page footer .logo_image_area img {
    /* width: 150px; */
  }
  #public_page footer .footer_menu ul {
    display: inline-block;
    width: calc(50% + 10px);
  }
  #public_page footer .footer_menu ul li {
    display: block;
  }
  #public_page footer .media_area {
    display: inline-block;
    width: calc(50% - 10px);
    vertical-align: top;
  }
  #public_page footer .fp_menu ul {
    display: inherit;
    padding-left: 0;
  }
  #public_page footer .fp_menu ul li {
    width: 100%;
    margin-bottom: 10px;
  }
  #public_page footer .fp_menu ul li.official_sns {
    margin-left: 0;
  }
  #public_page footer .logo_image_area {
    margin: 40px auto;
  }
  /* 特定商取引法 */
  #public_page #commercial_transaction_page th {
    width: 33%;
  }
  /* プライバシーポリシー */
  #public_page #privacy_policy_page .introduction {
    /* text-align: center;
    width: 60%;
    margin: 60px auto; */
  }
  /* お問い合わせ */
  #public_page #contact_page table th,
  #public_page #contact_page table td {
    display: block;
    width: 98%;
    text-align: left;
  }
  #public_page #contact_page table td .radio_btn_label {
    display: inline-block;
    margin: 10px 15px;
  }
  #public_page #contact_page table th {
    padding: 10px 0 10px 5px;
  }
  #public_page #contact_page table td {
    padding: 20px 0 20px 5px;
  }
  #public_page #contact_page table th span br {
    display: none;
  }
  #public_page #contact_page table td .family_name_area span,
  #public_page #contact_page table td .first_name_area span {
    text-align: left;
  }
  #public_page #contact_page table td .family_name_area input,
  #public_page #contact_page table td .first_name_area input {
    width: 100%;
  }
  #public_page #contact_page table td span {
    display: block;
    margin: 15px 0 0;
  }
  #public_page #contact_page table td textarea {
    width: 96%;
  }
  /* 商品一覧 */
  #public_page .list_type_1 .item_card {
    width: calc(33.3333333333% - 10px);
  }
  #public_page .list_type_1 .item_card .thumbnail_area {
    height: calc(23.3333333333vh - 10px);
  }
  /* 商品詳細 */
  #public_page #item_detail_page .item_info_area {
    display: block;
  }
  /* お気に入り一覧 */
  #public_page #item_list_page .item_card .btn_area button {
    width: 98%;
  }
  #public_page .list_type_1 .nostock .thumbnail_area::after {
    padding-top: 45%;
  }
  /* 新規会員登録・会員情報変更 */
  #public_page .form_type_1 table th,
  #public_page .form_type_1 table td {
    display: block;
    width: 98%;
  }
  #public_page .form_type_1 table th {
    padding: 4px 0 4px 5px;
    text-align: left;
  }
  #public_page .form_type_1 table td {
    padding: 25px 0 25px 5px;
  }
  #public_page .form_type_1 table td .family_name_area span,
  #public_page .form_type_1 table td .first_name_area span {
    text-align: left;
  }
  #public_page .form_type_1 table td .family_name_area input,
  #public_page .form_type_1 table td .first_name_area input {
    width: 100%;
  }
  #public_page .form_type_1 table td input[name=password],
  #public_page .form_type_1 table td input[name=re_password],
  #public_page .form_type_1 table td input[name=mail_address],
  #public_page .form_type_1 table td input[name=re_mail_address],
  #public_page .form_type_1 table td input[name=birthday],
  #public_page .form_type_1 table td input[name=address] {
    width: 90%;
  }
  #public_page .form_type_2 form dt span.required {
    display: inline-block;
  }
  #public_page .form_type_1 table td .family_name_area input,
  #public_page .form_type_1 table td .first_name_area input {
    width: calc(100% - 0.8em - 10px);
  }
  #public_page .form_type_1 table span {
    display: inline-block;
  }
  #public_page .form_type_1 table span br {
    display: none;
  }
  #public_page #member_edit_page .form_type_1 td button {
    width: 98%;
    margin-left: 0;
    margin-top: 20px;
  }
  #public_page #member_edit_page .form_type_1 tr:nth-child(4) td button {
    margin-left: 0;
  }
  /* お届けリスト */
  #public_page .search_add_area {
    display: block;
  }
  #public_page .search_add_area .right_content {
    margin: 20px auto;
  }
  /* 買い物カゴ */
  #public_page .list_type_2 th.item_name_th {
    width: 65%;
  }
  #public_page .list_type_2 th.delete_btn_th {
    width: 60px;
  }
  /* 買い物カゴ～お届け先情報入力 */
  #public_page .list_type_3 .order_card .delete_btn {
    margin-right: 0;
  }
  #public_page .list_type_3 .order_card .post_info {
    display: block;
  }
  #public_page .list_type_3 .order_card h3 {
    width: 100%;
  }
  /* 買い物カゴ～入力内容の確認 */
  #public_page .order_card_box .order_card dd span.item_name,
  #public_page .order_card_box .order_card dd span.standard {
    width: 100%;
    margin-bottom: 10px;
    text-align: left;
  }
  #public_page .order_card_box .order_card dd span.number,
  #public_page .order_card_box .order_card dd span.price {
    width: calc(50% - 30px);
    display: inline-block;
  }
  /* 購入履歴 */
  #public_page #order_history_page .list_area td:nth-child(2) {
    width: 45%;
  }
}
@media screen and (max-width: 580px) {
  #public_page .breadcrumb {
    display: none;
  }
  /* 商品一覧 */
  #public_page .list_type_1 .item_card {
    width: calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
  #public_page .list_type_1 .item_card .thumbnail_area {
    height: calc(22.5vh - 10px);
  }
  /* 商品詳細 */
  #public_page #item_detail_page .item_info_area .right_content {
    width: 100%;
  }
  /* お気に入り一覧 */
  #public_page .btn_area {
    margin-top: 65px;
  }
  #public_page .list_type_1 .nostock .thumbnail_area::after {
    padding-top: 35%;
  }
  /* 買い物カゴ～ */
  #public_page .order_info_link_btn,
  #public_page .order_post_info_link_btn {
    display: block;
    margin: 15px auto !important;
  }
  #public_page .list_type_2 td.item_image_name_box div {
    /* width: 100%; */
    /* margin-left: 0; */
    /* margin-top: 20px; */
  }
  /* 買い物カゴ～お届け情報追加 */
  #public_page .add_address_btn_area {
    display: flex;
    justify-content: space-around;
    flex-flow: column;
    gap: 10px 0px;
  }
  #public_page .add_address_btn_area button {
    padding: 15px 10px;
    /* margin: 0 auto;   */
  }
  /* 買い物カゴ～お届け先情報入力 */
  #public_page .list_type_3 .order_card form .item_name,
  #public_page .list_type_3 .order_card form .item_standard {
    width: 100%;
    margin-bottom: 10px;
  }
  #public_page footer .copylight_area p span {
    display: block;
  }
}
@media screen and (max-width: 500px) {
  /* 一般ぺージ(共通) */
  #public_page #temp_1col h1,
  #public_page #temp_2col h1 {
    font-size: 22px;
  }
  /* サイドバー(一般) */
  /* #public_page article .col_2_wrap main.top_main_content {
    order: 1;
  }
  #public_page article .col_2_wrap aside.sidebar {
    order: 2;
  } */
  #public_page article .col_2_wrap aside.sidebar .search_area {
    /* display: none; */
  }
  #public_page article .col_2_wrap aside.sidebar .menu_list2 {
    /* display: none; */
  }
  #public_page article .col_2_wrap aside.sidebar {
    padding-bottom: 60px;
    /* padding-bottom: 0px; */
  }
  #public_page article .col_2_wrap aside.sidebar ul li {
    display: inline-block;
    width: 100%;
    margin: 10px 0;
  }
  #public_page article .col_2_wrap aside.sidebar ul li:nth-last-child(1) {
    border-bottom: dashed 1px #ccc;
  }
  /* ヘッダー(一般) */
  #public_page header {
    border-top: 0;
  }
  #public_page header .left_area .menu_toggle_btn span:nth-of-type(1) {
    top: 55px;
  }
  #public_page header .left_area .menu_toggle_btn span:nth-of-type(2) {
    top: 65px;
  }
  #public_page header .left_area .menu_toggle_btn span:nth-of-type(3) {
    top: 75px;
  }
  #public_page header .left_area .menu_toggle_btn span:nth-of-type(1) {
    top: 2px;
  }
  #public_page header .left_area .menu_toggle_btn span:nth-of-type(2) {
    top: 12px;
  }
  #public_page header .left_area .menu_toggle_btn span:nth-of-type(3) {
    top: 22px;
  }
  /* フッター(一般) */
  #public_page footer .footer_menu ul li {
    display: block;
  }
  #public_page footer .contact_infomation_area .telnumber_area,
  #public_page footer .contact_infomation_area .mailform_area {
    margin: 0 auto;
  }
  #public_page .btn_type_2 {
    padding: 8px 70px;
  }
  #public_page footer .footer_menu ul {
    width: 100%;
  }
  #public_page footer .media_area {
    width: 100%;
    text-align: start;
    margin-left: 20px;
  }
  #public_page #sidemenu_area {
    width: 100%;
  }
  #public_page #sidemenu_area .login_btn_area button {
    /* margin: 10px auto; */
    /* display: block; */
    width: 125px;
  }
  .pc_show {
    display: none !important;
  }
  .sp_show {
    display: block;
  }
  /* 一般ぺージ(TOP) */
  #public_page .shop_introduce_area .text_area img {
    float: initial;
    width: 95%;
    margin: 0 auto;
  }
  #public_page .recommend_item_list_area .recommend_item_card,
  #public_page .recommend_item_list_area .recommend_item_card.big_card {
    width: calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
  #public_page .recommend_item_list_area .recommend_item_card .thumbnail_area,
  #public_page .recommend_item_list_area .recommend_item_card.big_card .thumbnail_area {
    height: calc(27.5vh - 10px);
  }
  #public_page .item_list_area .item_card {
    width: calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
  #public_page .item_list_area .item_card .thumbnail_area {
    height: calc(27.5vh - 10px);
  }
  /* ご利用について */
  #public_page #temp_1col section h2 {
    font-size: 20px;
  }
  /* お知らせ一覧 */
  #public_page #news_list_page .news_card .right_content {
    width: 150px;
    height: 150px;
    margin-top: 15%;
  }
  /* 買い物カゴ */
  #public_page .list_type_2 .scroll_wrap {
    overflow: auto;
  }
  #public_page .list_type_2 .scroll_wrap table {
    width: 500px;
  }
  /* 買い物カゴ～お届け先情報入力 */
  #public_page .order_post_info_content .introduction button {
    margin-left: 0;
    margin-top: 20px;
  }
  /* お届けリスト */
  #public_page .list_area .scroll_wrap {
    overflow: auto;
  }
  #public_page .list_area .scroll_wrap table {
    width: 500px;
  }
  #public_page .sp_txt {
    display: block;
    margin-left: 10px;
  }
}
@media screen and (max-width: 400px) {
  /* ヘッダー(一般) */
  #public_page header .right_area {
    display: flex;
    align-items: flex-start;
    right: 0;
  }
  /* お問い合わせ */
  #public_page #contact_page table td .family_name_area,
  #public_page #contact_page table td .first_name_area {
    width: 300px;
  }
  #public_page #contact_page table td .first_name_area {
    margin-left: 0;
  }
  /* お知らせ一覧 */
  #public_page #news_list_page .news_card .right_content {
    width: 100px;
    height: 100px;
    margin-top: 20%;
    margin-left: 10px;
  }
  /* 商品一覧・お気に入り一覧 */
  #public_page .list_type_1 .item_card {
    width: 100%;
    max-width: 100%;
  }
  #public_page .list_type_1 .item_card .thumbnail_area {
    height: calc(30vh - 10px);
  }
  #public_page .btn_area {
    margin-top: 45px;
  }
  /* 商品詳細 */
  #public_page #item_detail_page .item_info_area .left_content p.name {
    font-size: 20px;
  }
  /* お届けリスト */
  #public_page .search_add_area .left_content input {
    width: 60%;
  }
  /* 買い物カゴ～お届け先情報追加 */
  #public_page .add_address_btn_area button {
    margin: 0 10px;
  }
}
/* 招待状・席次表作成画面 */
#editor_page {
  height: 100%;
}

#editor_page header {
  position: absolute;
  display: flex;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background-color: #373239;
  color: #ffffff;
  padding: 20px 30px;
  box-sizing: border-box;
}

#editor_page header .headerlogo {
  width: 150px;
}

#editor_page header h1 {
  margin: 0 20px;
}

#editor_page header .delivery_date {
  border: 1px solid #ffffff;
  text-align: center;
  padding: 10px 20px;
  box-sizing: border-box;
  color: #b9b9b9;
}

#editor_page header .delivery_date p {
  margin: 0;
}

#editor_page header .delivery_date span {
  color: #ffffff;
  font-size: 14px;
}

#editor_page header .delivery_date span {
  color: #ffffff;
  font-size: 14px;
}

#editor_page header #canvas_submit {
  margin-left: auto;
  background-color: #969696;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
}

#editor_page header #pdf_export_btn {
  background-color: #fff;
  color: #000;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
}

#editor_page header #canvas_submit span {
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 15px;
  padding-left: 20px;
}

#editor_page header .mypage_ancer {
  margin: 0 20px;
  background-color: var(--theme-color-1);
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
}

#editor_page header .mypage_ancer span {
  background-repeat: no-repeat;
  background-position: center left;
  background-size: 15px;
  padding-left: 20px;
}

#editor_page header .ancer_link a {
  color: #ffffff;
  text-decoration: none;
}

#editor_page main {
  width: 100%;
  min-width: 431px;
  overflow: hidden;
  height: 100%;
  padding-top: 50px;
  box-sizing: border-box;
  padding-bottom: 0;
}

#editor_page aside {
  float: left;
  width: 430px;
  height: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

#editor_page aside #tab_area {
  float: left;
  width: 100px;
  height: 100%;
  background-color: #969696;
  color: #ffffff;
  font-size: 10px;
}

#editor_page aside #tab_area ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#editor_page aside #tab_area ul li {
  cursor: pointer;
  text-align: center;
  padding: 25px 10px;
  border-bottom: 1px solid #ffffff;
}

#editor_page aside #tab_area ul li.active {
  background-color: #6698cb;
}

#editor_page aside #tab_area ul li:hover {
  background-color: #6698cb;
}

#editor_page aside #edit_area {
  float: left;
  width: 330px;
  overflow-y: scroll;
  height: 100%;
  position: relative;
}

#editor_page aside #edit_area .edit_content_box {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 20px 50px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}

#editor_page aside #edit_area .edit_content_box.show {
  display: block;
}

#editor_page aside #edit_area .edit_content_box h2 {
  border-bottom: 1px solid #b1b1b1;
  padding-bottom: 15px;
  font-size: 20px;
  text-align: center;
}

#editor_page aside #edit_area .edit_content_box .edit_box {
  margin-bottom: 50px;
}

#editor_page aside #edit_area .edit_content_box #profile_group .edit_box,
#editor_page aside #edit_area .edit_content_box #interview_group .edit_box,
#editor_page aside #edit_area .edit_content_box #menu_group .edit_box,
#editor_page aside #edit_area .edit_content_box #guest_card_list_area .edit_box {
  margin-bottom: 0px;
}

#editor_page aside #edit_area .edit_content_box .edit_box .tag_number_input_area {
  margin-top: 10px;
  display: flex;
  align-items: baseline;
}

#editor_page aside #edit_area .edit_content_box .edit_box .tag_number_input_area span {
  margin-left: 10px;
  padding-bottom: 20px;
}

#editor_page aside #edit_area .edit_content_box .edit_box .col_2 {
  display: flex;
  justify-content: space-between;
}

#editor_page aside #edit_area .edit_content_box .edit_box .col_3 {
  display: flex;
  justify-content: space-between;
}

#editor_page aside #edit_area .envelope_edit_area .address_note,
#editor_page aside #edit_area .postcard_frontside_edit_area .address_note {
  text-align: left;
  font-size: 12px;
  color: gray;
}

#editor_page aside #edit_area .edit_content_box h3 {
  text-align: center;
  background-color: gray;
  color: white;
  /* border-radius: 0 10px 0 10px; */
  border-radius: 10px 0 10px 0;
  margin-bottom: 20px;
}

#editor_page aside #edit_area #seating_seat_edit_area h3 {
  /* padding: 10px 10px 10px 20px;
  margin-bottom: 20px; */
}

#editor_page aside #edit_area .edit_content_box input {
  width: 100%;
  box-sizing: border-box;
  padding: 15px 5px;
  border-radius: 5px;
  border: none;
  background-color: #f0f0f0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

#editor_page aside #edit_area .edit_content_box input[type=radio] {
  width: initial;
  margin-right: 10px;
  vertical-align: text-top;
}

#editor_page aside #edit_area .edit_content_box input[type=text]:-moz-read-only {
  background-color: #9b9b9b;
  color: #646464 !important;
}

#editor_page aside #edit_area .edit_content_box input[type=text]:disabled,
#editor_page aside #edit_area .edit_content_box input[type=text]:read-only {
  background-color: #9b9b9b;
  color: #646464 !important;
}

#editor_page aside #edit_area #seating_additional_comment_edit_area input[name=seating_additional_comment] {
  height: 300px;
  word-break: break-all;
  word-wrap: break-word;
}

#editor_page aside #edit_area .edit_content_box label {
  display: block;
  width: 100%;
  font-size: 12px;
  color: #969696;
  margin-bottom: 3px;
}

#editor_page aside #edit_area .edit_content_box .edit_box .editor_text_input_item {
  margin-bottom: 20px;
}

#editor_page aside #edit_area .edit_content_box .edit_box .col_1 .editor_text_input_item {
  width: 100%;
}

#editor_page aside #edit_area .edit_content_box .edit_box .col_2 .editor_text_input_item {
  width: calc(50% - 10px);
}

#editor_page aside #edit_area .edit_content_box .edit_box .col_3 .editor_text_input_item {
  width: calc(33.3333333333% - 10px);
}

#editor_page aside #edit_area .edit_content_box .edit_box .col_1 .editor_radio_input_item {
  width: 100%;
}

#editor_page aside #edit_area .edit_content_box .edit_box .col_2 .editor_radio_input_item {
  width: calc(50% - 10px);
}

#editor_page aside #edit_area .edit_content_box .edit_box .col_3 .editor_radio_input_item {
  width: calc(33.3333333333% - 10px);
}

#editor_page aside #edit_area .edit_content_box .edit_box .editor_radio_input_item label {
  color: inherit;
  font-size: inherit;
}

#editor_page aside #edit_area .edit_content_box .edit_box .editor_radio_input_item label {
  color: inherit;
  font-size: inherit;
}

#editor_page aside #edit_area .edit_content_box .post_date {
  text-align: center;
  margin-top: 20px;
}

#editor_page aside #edit_area .edit_content_box .post_date .pre {
  display: inline-block;
  background: #a7d1de;
  width: 20px;
  height: 20px;
  position: relative;
  margin: 0 20px;
  border: none;
  vertical-align: middle;
}

#editor_page aside #edit_area .edit_content_box .post_date .pre span {
  border-top: 5px solid transparent;
  border-right: 8px solid white;
  border-bottom: 5px solid transparent;
  display: inline-block;
  position: absolute;
  top: 5px;
  left: 5px;
}

#editor_page aside #edit_area .edit_content_box .post_date .next {
  display: inline-block;
  background: #a7d1de;
  width: 20px;
  height: 20px;
  position: relative;
  margin: 0 20px;
  border: none;
  vertical-align: middle;
}

#editor_page aside #edit_area .edit_content_box .post_date .next span {
  border-top: 5px solid transparent;
  border-left: 8px solid white;
  border-bottom: 5px solid transparent;
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 5px;
}

#editor_page aside #edit_area .edit_content_box textarea {
  width: 100%;
  resize: none;
  padding: 10px;
  border: none;
  background-color: #f0f0f0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  box-sizing: border-box;
  margin-bottom: 20px;
}

#editor_page aside #edit_area .edit_content_box textarea:-moz-read-only {
  background-color: #9b9b9b;
  color: #646464 !important;
}

#editor_page aside #edit_area .edit_content_box textarea:read-only {
  background-color: #9b9b9b;
  color: #646464 !important;
}

#editor_page aside #edit_area .edit_content_box #menu_group textarea {
  margin-bottom: 0px;
  word-break: break-all;
}

#editor_page aside #edit_area .edit_content_box .date_place_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#editor_page aside #edit_area .edit_content_box .margin_bottom_20 {
  margin-bottom: 20px;
}

#editor_page aside #edit_area .edit_content_box .edit_box .date_place_item .editor_text_input_item:first-child {
  width: calc(33.3333333333% - 20px);
  margin-bottom: 0;
}

#editor_page aside #edit_area .edit_content_box .edit_box .date_place_item .editor_text_input_item:nth-child(2) {
  width: calc(33.3333333333% + 20px);
  margin-bottom: 0;
}

#editor_page aside #edit_area .edit_content_box .edit_box .date_place_item label.editor_item {
  width: calc(33.3333333333% - 20px);
}

#editor_page aside #edit_area .edit_content_box .edit_box label.editor_item input {
  width: 15px;
  height: 15px;
  vertical-align: middle;
  margin-right: 5px;
}

#editor_page aside #edit_area .edit_content_box .edit_box label.editor_item input span {
  font-size: 14px;
  vertical-align: middle;
}

#editor_page aside #edit_area .edit_content_box .edit_box span.show_maiden_name_span {
  margin: 10px 0 5px;
  display: block;
}

#editor_page aside .profile_interview_edit_area ul {
  list-style: none;
  padding: 0;
  word-break: break-all;
}

#editor_page aside .small_text {
  font-size: 0.7em;
}

#editor_page aside .edit_box_group {
  /* margin-bottom: 10px; */
}

#editor_page aside .edit_box_group .edit_move_box {
  /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%); */
  cursor: pointer;
  display: none;
  height: 100%;
}

#editor_page aside .edit_box_group .edit_move_box.show {
  display: block;
}

#editor_page aside .edit_box_group .edit_move_box table {
  width: 100%;
  z-index: -10;
}

#editor_page aside .edit_box_group .edit_move_box table th {
  width: 3em;
  text-align: left;
}

#editor_page aside .edit_box_group .edit_box {
  /* padding: 0px 10px 10px;
  border: 1px solid #d6ab73;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%);
  cursor: pointer;
  margin-bottom: 0; */
  display: none;
}

#editor_page aside .edit_box_group .edit_box.show {
  display: block;
}

#editor_page aside .edit_box_group .edit_box p {
  text-align: right;
  font-size: 12px;
  color: #ffffff;
  background-color: #d6ab73;
  margin: 0 0 10px 0;
  cursor: pointer;
  text-align: center;
}

#editor_page aside .edit_box_group p.textarea_pre {
  white-space: pre-wrap;
  word-break: break-all;
}

#editor_page aside .dd-box {
  list-style: none;
  padding: 10px;
}

#seating_seat_edit_area .seating_form_box {
  /* border: 1px solid gray; */
}

#seating_seat_edit_area .seating_form_box h3 {
  margin: 0;
  background-color: gray;
  /* background-color: whitesmoke; */
  color: white;
  /* border-top: 5px solid whitesmoke; */
  border-radius: 20px;
}

#seating_seat_edit_area .seating_form_box .add_seat_note {
  text-align: center;
  margin-bottom: 10px;
}

#seating_seat_edit_area .section {
  /* padding: 0 10px; */
  margin-bottom: 50px;
}

#seating_seat_edit_area .section .section_title {
  font-weight: bold;
  background-color: whitesmoke;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 1px double gray;
  border-bottom: 1px double gray;
  width: 85%;
  margin: 30px auto 20px auto;
  font-size: 14px;
}

#seating_seat_edit_area .section .section_title span.small_text {
  color: gray;
  font-size: 10px;
}

#seating_seat_edit_area .section .section_contents {
  padding: 0 20px;
}

#seating_seat_edit_area #add_guest_card_area .add_btn_area button {
  /* border: none; */
  /* border-top: 1px solid gray; */
  width: 70%;
}

#editor_page aside .add_btn_area {
  /* margin: 0 0 20px 0; */
  /* margin: 0 auto; */
  text-align: center;
}

#editor_page aside .add_btn_area button {
  background-color: #b9b59d;
  font-weight: bold;
  /* color: #1a1a1a; */
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  width: 100%;
  border: 1px solid gray;
}

#editor_page aside .bulk_add_guest_btn_area button {
  background-color: #e4767c;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
}

#editor_page aside .dd-box li {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  background: #fff;
  border: 1px solid #c6c6c6;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  /* cursor: grab; */
  max-width: 500px;
  transition: box-shadow 0.3s;
  padding: 10px;
}

#editor_page aside .dd-box li.onGrab {
  cursor: grabbing;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.15);
  position: absolute;
  z-index: 1;
}

#editor_page aside .dd-box li:not(:last-child) {
  margin-bottom: 10px;
}

#editor_page aside .hidden {
  visibility: hidden;
}

#editor_page aside .mode_btn {
  border: 1px solid #333333;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  transition: font-size 0.25s;
  text-align: center;
}

#editor_page aside .drag_item {
  border: 1px solid #333333;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  transition: font-size 0.25s;
}

#editor_page aside .drag_item.active {
  padding: 10px;
  font-size: 8px;
}

#editor_page aside .drag_item.add_seat {
  width: 200px;
  margin: 0 auto;
  text-align: center;
  border: 2px dashed gray;
  padding: 5px;
  box-shadow: 3px 3px 2px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

#editor_page aside .drag_item.add_seat .seat_name {
  color: red;
  font-weight: bold;
}

#editor_page aside .drag_item.add_seat .guide_text {
  font-size: 10px;
}

#editor_page aside .drag_item span {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

#editor_page .drop_box_element {
  pointer-events: none;
  /* opacity: 0; */
}

#editor_page .drop_box_element.ondrag {
  pointer-events: inherit;
  /* opacity: 1; */
}

#editor_page .drop_box_element.active {
  stroke-width: 2;
}

#editor_page .guest rect.active {
  stroke-width: 2;
}

#editor_page .guest rect.ondrag {
  stroke: #ff0000;
}

#editor_page .guest:hover .close_btn {
  display: block;
}

#editor_page .guest .close_btn {
  display: none;
}

#editor_page .seating_form_box .seating_form_note {
  text-align: center;
  margin-bottom: 10px;
}

#editor_page #guest_card_list_area ul {
  list-style: none;
  padding: 10px;
}

#editor_page #guest_card_list_area ul li {
  /* width: 250px; */
  background-color: white;
  margin: 0 auto;
  margin-bottom: 5px;
  font-size: 11px;
  border: none;
  box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.2);
}

#editor_page #edit_area .edit_content_box .edit_box #guest_card_list_area ul li .editor_text_input_item {
  font-size: 11px;
  margin-bottom: 0;
}

#editor_page #edit_area .edit_content_box .edit_box #guest_card_list_area ul li .editor_text_input_item input {
  font-size: 11px;
}

#editor_page #edit_area .edit_content_box .edit_box #guest_card_list_area ul li .editor_textarea_item textarea {
  margin-bottom: 10px;
  font-size: 11px;
}

#editor_page #guest_card_list_area ul#guest_card_list {
  /* border: 1px solid gray; */
  background-color: #fffeed;
  margin-bottom: 50px;
  border-left: 1px solid gray;
}

#editor_page #guest_card_list_area ul#already_deploy_guest_card_list {
  /* border: 1px solid gray; */
  background-color: #d9ffd7;
  background-color: #edeeff;
  background-color: #edfffe;
  border-left: 1px solid gray;
}

/* #editor_page #guest_card_list_area ul#guest_card_list li {
  background-color: #ffd7d9;
}
#editor_page #guest_card_list_area ul#already_deploy_guest_card_list li {
  background-color: #d9ffd7;
} */
#editor_page #preview_area {
  position: relative;
  min-height: 100%;
  height: 100%;
  min-width: 1px;
  /* overflow: hidden; */
  overflow: scroll;
}

#editor_page #canvas {
  font-size: 0;
  transform-origin: left top;
  position: absolute;
  /* width: 100%; */
  /* height: 100%; */
  /* min-width: 1300px; */
  /* min-height: 900px; */
  width: 1300px;
  height: 870px;
}

#editor_page #canvas .svg_wrap {
  position: absolute;
  transform-origin: left top;
  /* display: none; */
}

#editor_page #canvas .svg_wrap.invitation_svg {
  display: block;
}

#editor_page svg {
  /* border: 1px solid #000000; */
  /* SVG内のTextを選択不可にする */
  /* -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none; */
}

#editor_page svg#fusen_svg {
  border: none;
}

#editor_page .none {
  visibility: hidden;
  pointer-events: none;
}

#editor_page svg.none {
  visibility: hidden;
  pointer-events: none;
}

#editor_page svg.none * {
  pointer-events: none;
}

#editor_page svg .event_none {
  pointer-events: none;
}

#editor_page svg .table_element_group.event_none {
  opacity: 0.4;
}

#editor_page #image_editor_area {
  width: 100%;
  height: 100%;
  display: none;
}

#editor_page #image_editor_area #iamge_editor_element {
  transform-origin: left top;
}

#editor_page #image_editor_area svg {
  /* border: none; */
  position: absolute;
  top: 0;
  left: 0;
}

#editor_page #image_editor_area svg .outline_group {
  fill: none;
  opacity: 1;
  stroke: #000000;
  stroke-dasharray: 5, 5;
  stroke-dashoffset: 10;
  animation: dash 120s linear;
}
@keyframes dash {
  to {
    stroke-dashoffset: 3000;
  }
}
#editor_page #image_editor_area .image_size_button {
  position: absolute;
}

#editor_page #image_editor_area #image_size_button_TL.image_size_button {
  top: -30px;
  left: -30px;
}

#editor_page #image_editor_area #image_size_button_TR.image_size_button {
  top: -30px;
  left: 0;
}

#editor_page #image_editor_area #image_size_button_BR.image_size_button {
  top: 0;
  left: 0;
}

#editor_page #image_editor_area #image_size_button_BL.image_size_button {
  top: 0;
  left: -30px;
}

#editor_page #image_editor_area .image_size_button .button_wrap {
  width: 30px;
  height: 30px;
}

#editor_page #image_editor_area svg {
  pointer-events: none;
}

/* 招待客登録 */
#guest_add_page .title_area {
  background-color: #e6e6e6;
  padding: 20px 30px;
}

#guest_add_page .table_area {
  /* border: 1px solid #e6e6e6; */
  padding: 20px 30px;
}

#guest_add_page table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

#guest_add_page table th,
#guest_add_page table td {
  border: 1px solid #e6e6e6;
  padding: 10px;
}

#guest_add_page .table_area ul.caution {
  padding-left: 1em;
}

#guest_add_page .no_padding_table_area {
  padding: 0;
}

#guest_add_page .no_padding_table_area table {
  width: 100%;
  border-collapse: collapse;
}

#guest_add_page .no_padding_table_area table th {
  padding: 10px;
  border: 1px solid #e6e6e6;
  border-top: none;
  color: #e0a046;
}

/* グループ編集 */
#guest_add_page .group_edit_area .shadow_area {
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(60, 60, 60, 0.6);
  z-index: 10;
  top: 0;
  left: 0;
}

#guest_add_page .group_edit_area .content_area {
  width: 600px;
  margin: 150px auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
  height: 700px;
}

#guest_add_page .group_edit_area .content_area h2 {
  padding: 0 30px 10px;
  border: none;
  margin: 0;
}

#guest_add_page .group_edit_area .content_area form {
  display: inline;
}

#guest_add_page .group_edit_area .content_area .close_btn_area {
  text-align: right;
  padding: 0 10px 0;
  font-size: 24px;
  color: #b1b1b1;
}

#guest_add_page .group_edit_area .content_area .close_btn_area span {
  cursor: pointer;
}

#guest_add_page .group_edit_area .content_area .add_area {
  padding: 0 30px 30px;
}

#guest_add_page .group_edit_area .content_area .add_area button {
  padding: 8px 20px;
  color: #ffffff;
  background-color: #6698cb;
  border: none;
  margin-left: 30px;
}

#guest_add_page .group_edit_area .content_area .update_delete_area {
  border-top: 2px solid #b1b1b1;
  overflow-y: scroll;
  height: 450px;
}

#guest_add_page .group_edit_area .content_area .list_area {
  padding: 30px;
}

#guest_add_page .group_edit_area .content_area .list_area table {
  width: 100%;
}

#guest_add_page .group_edit_area .content_area .list_area table td {
  line-height: 55px;
  /* border-bottom: 1px solid #e6e6e6; */
}

#guest_add_page .group_edit_area .content_area .list_area table td:nth-child(2) {
  width: 90px;
  text-align: center;
}

#guest_add_page .group_edit_area .content_area .list_area table td:nth-child(2) button {
  padding: 8px 20px;
  color: #ffffff;
  background-color: #6698cb;
  border: none;
}

#guest_add_page .group_edit_area .content_area .list_area table td:nth-child(3) {
  width: 90px;
  text-align: center;
}

#guest_add_page .group_edit_area .content_area .list_area table td:nth-child(3) button {
  padding: 8px 20px;
  color: #ffffff;
  background-color: #b1b1b1;
  border: none;
}

/* 宛名リスト画面 */
#guest_list_page #loaded_guest_list_table_area {
  margin-bottom: 100px;
}

#guest_list_page p {
  text-align: center;
}

#guest_list_page a#guest_add_excel_link {
  /* margin-left: 20px; */
  color: #0000ff;
  text-decoration: underline;
  cursor: pointer;
}

#guest_list_page #loaded_guest_list_table_area {
  min-height: 500px;
}

#guest_list_page .loaded_result_title {
  font-size: 20px;
  text-align: center;
  background-color: var(--theme-color-1);
  color: white;
  margin-bottom: 0;
}

/* 宛名登録画面 */
#guest_add_excel_page #operation_area {
  margin-bottom: 100px;
}

#guest_add_excel_page .operation_guide {
  background-color: whitesmoke;
  border: 1px solid #000;
  margin-top: 30px;
}

#guest_add_excel_page .operation_guide .guide_text {
  margin: 0;
  padding-left: 20px;
  background-color: var(--theme-color-1);
  color: white;
  text-align: left;
  font-weight: bold;
}

#guest_add_excel_page .operation_guide .operation_area {
  text-align: center;
  margin: 20px 20px;
}

#guest_add_excel_page p {
  text-align: center;
}

#guest_add_excel_page a#template_excel_download_link {
  /* margin-left: 20px; */
  color: #0000ff;
  text-decoration: underline;
}

#guest_add_excel_page #loaded_guest_list_table_area {
  min-height: 500px;
}

#guest_add_excel_page .loaded_result_title {
  font-size: 20px;
  text-align: center;
  background-color: var(--theme-color-1);
  color: white;
  margin-bottom: 0;
}

#guest_add_excel_page .register_btn_area .register_guest_list_btn {
  display: block;
  height: 50px;
  width: 200px;
  margin: 0 auto;
  margin-top: 20px;
  font-size: 15px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  background-color: var(--theme-color-1);
  color: #fff;
  text-align: center;
}

#guest_add_excel_page span.red_text {
  color: red;
}

#public_page .cart_sub_item_table_wrapper {
  width: 100%;
  border: 1px solid gray;
}
#public_page .cart_sub_item_table_wrapper .MuiDataGrid-root .MuiDataGrid-columnHeaders {
  background-color: #edeae4;
  border-bottom: 1px solid gray;
}
#public_page .cart_sub_item_table_wrapper .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
#public_page .cart_sub_item_table_wrapper .MuiDataGrid-root .MuiDataGrid-cell:focus,
#public_page .cart_sub_item_table_wrapper .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
#public_page .cart_sub_item_table_wrapper .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none;
}
#public_page .cart_sub_item_table_wrapper .MuiDataGrid-root .MuiDataGrid-footerContainer {
  display: none;
}
#public_page .member_guest_table_wrapper {
  border: 1px solid gray;
}
#public_page .member_guest_table_wrapper .MuiDataGrid-root .MuiDataGrid-columnHeaders {
  background-color: #edeae4;
  border-bottom: 1px solid gray;
}
#public_page .member_guest_table_wrapper .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
#public_page .member_guest_table_wrapper .MuiDataGrid-root .MuiDataGrid-cell:focus,
#public_page .member_guest_table_wrapper .MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
#public_page .member_guest_table_wrapper .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none;
}
#public_page .shipping_fee_box table.shipping_fee {
  margin: auto;
  margin-top: 50px;
  width: 50%;
  min-width: 350px;
  border-collapse: collapse;
}
#public_page .shipping_fee_box table.shipping_fee tbody tr {
  height: 30px;
}
#public_page .shipping_fee_box table.shipping_fee tbody tr th {
  width: 50%;
  border: 1px solid gray;
  background-color: #edeae4;
}
#public_page .shipping_fee_box table.shipping_fee tbody tr td {
  border: 1px solid gray;
  text-align: center;
}
#public_page .express_delivery_fee_box table.express_delivery_fee {
  margin: auto;
  margin-top: 20px;
  width: 50%;
  min-width: 350px;
  border-collapse: collapse;
}
#public_page .express_delivery_fee_box table.express_delivery_fee tbody tr th {
  width: 50%;
  border: 1px solid gray;
  background-color: #edeae4;
}
#public_page .express_delivery_fee_box table.express_delivery_fee tbody tr th span {
  display: block;
  font-size: 0.8em;
}
#public_page .express_delivery_fee_box table.express_delivery_fee tbody tr td {
  width: 50%;
  border: 1px solid gray;
  text-align: center;
}
#public_page .express_delivery_fee_box table.express_delivery_fee tbody tr td.is_express_delivery_select select {
  height: 25px;
}
#public_page .express_delivery_fee_box table.express_delivery_fee tbody tr td.can_select {
  width: 25%;
}
#public_page .isolated_islands_shipping_fee_box table.isolated_islands_shipping_fee {
  margin: auto;
  margin-top: 20px;
  width: 50%;
  min-width: 300px;
  border-collapse: collapse;
}
#public_page .isolated_islands_shipping_fee_box table.isolated_islands_shipping_fee tbody tr {
  height: 30px;
}
#public_page .isolated_islands_shipping_fee_box table.isolated_islands_shipping_fee tbody tr th {
  width: 50%;
  border: 1px solid gray;
  background-color: #edeae4;
}
#public_page .isolated_islands_shipping_fee_box table.isolated_islands_shipping_fee tbody tr td {
  border: 1px solid gray;
  text-align: center;
}
#public_page #cart_list_page .sub_item_quantity {
  text-align: center;
  width: 80px;
}
#public_page #cart_list_page .delete_btn {
  display: block;
  margin: auto;
  margin-top: 20px;
}
#public_page #cart_list_page .guest_list_wrapper {
  margin-top: 100px;
}/*# sourceMappingURL=index.css.map */