@page {
  /* size: A4 landscape; */
  size: 1233px 729px;
  /* size: auto; */
  margin: 0;
}

/* ページ分割 */
.print_page {
  break-before: page;
  /* width: 100%;
  height: 100%; */
}

.print_off,
#editor_page header.print_off {
  display: none;
}
